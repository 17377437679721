<template>
	<el-menu
			:default-active.sync="activeLink"
			:mode="mode"
			@select="goto"
			:collapse="isCollapse"
			:unique-opened="true"
			background-color="transparent"
			class="main-navigation-menu"
			:class="{'nav-collapsed':isCollapse}">
		<!--<el-submenu-->
				<!--index="paket"-->
				<!--popper-class="main-navigation-submenu">-->
			<!--<template slot="title">-->
				<!--<i class="mdi mdi-image-filter-none"></i><span>Paket</span>-->
			<!--</template>-->
			<!--<el-menu-item index="paket">-->
				<!--<span slot="title">Paket Detay</span>-->
			<!--</el-menu-item>-->
		<!--</el-submenu>-->
		<!--<el-submenu-->
				<!--index="urun"-->
				<!--popper-class="main-navigation-submenu">-->
			<!--<template slot="title">-->
				<!--<i class="mdi mdi-image-filter-none"></i><span>Ürün</span>-->
			<!--</template>-->
			<!--<el-menu-item index="urun">-->
				<!--<span slot="title">Ürün Detay</span>-->
			<!--</el-menu-item>-->
		<!--</el-submenu>-->
		<!--<el-submenu-->
				<!--index="satis"-->
				<!--popper-class="main-navigation-submenu">-->
			<!--<template slot="title">-->
				<!--<i class="mdi mdi-image-filter-none"></i><span>Satış</span>-->
			<!--</template>-->
			<!--<el-menu-item index="satis">-->
				<!--<span slot="title">Satış Detay</span>-->
			<!--</el-menu-item>-->
		<!--</el-submenu>-->
	</el-menu>
</template>

<script>
	import {detect} from 'detect-browser'
	import JQuery from 'jquery';

	let $ = JQuery;
	const browser = detect()
	let base64 = require('base64_js');
	let userDataKaderFoodKasa= "";
	export default {
		name: 'Nav',
		props: ['mode', 'isCollapse'],
		data() {
			return {
				root: false,
				yetkili: false,
				isIe: true,
				isEdge: true,
				activeLink: null,
				tabList: [],
				yetkiList: {
					yoneticiTanimlama: false,
					yoneticiListesi: false,
					sayfaTanimlama: false,
					haberTanimlama: false,
					haberKategoriTanimlama: false,
					sayfaListesi: false,
					haberListesi: false,
					haberKategoriListesi: false,
					popupTanimlama: false,
					popuplistesi: false,
					sliderTanimlama: false,
					sliderListesi: false,
					iletisim: false,
					urunTanimlama : false,
					urunListeleme : false,
					urunKategoriTanimlamaYet :false,
					urunOpsiyonTanimlamaYet :false,
				}
			}
		},
		methods: {
			goto(index, indexPath, id) {
				let self = this;
				if (this.$store.state.tabList.length === 0) {
					this.tabList = [];
				}
				var obj = this.tabList.find(data => data.title === id.$el.textContent);
				var name = linkTemizle(id.$el.textContent.replace(" ", ""));
				if (obj === undefined) {
					this.tabList.push({
						title: id.$el.textContent,
						name: name,
						contentName: name
					});
					this.$store.commit('changeTabList', this.tabList);
					this.$store.commit('changeActiveTabs', name);
				} else {
					this.$store.commit('changeActiveTabs', name);
				}
				this.$emit('push-page', {page: index});
				$(".horizontal-nav.pos-top").addClass("shadowBox");

			},
			closeSidebar() {
				this.openSidebar = false
			},
			setLink(path) {
				this.activeLink = path
			}
		},
		created() {
			if (browser.name !== 'ie') this.isIe = false
			if (browser.name !== 'edge') this.isEdge = false
			this.setLink(this.$router.currentRoute.path)
			this.$router.afterEach((to, from) => {
				this.setLink(this.$router.currentRoute.path)
			})
		},
		mounted() {
			let self = this;
			if (localStorage.getItem("userDataKaderFoodKasa")) {
				userDataKaderFoodKasa= JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
				if (userDataKaderFoodKasa["yetki"]) {

				}

			}
		},
	}

	function linkTemizle(text) {
		var trMap = {
			'çÇ': 'c',
			'ğĞ': 'g',
			'şŞ': 's',
			'üÜ': 'u',
			'ıİ': 'i',
			'öÖ': 'o'
		};
		for (var key in trMap) {
			text = text.replace(new RegExp('[' + key + ']', 'g'), trMap[key]);
		}
		return text.replace(/[^-a-zA-Z0-9\s]+/ig, '')
				.replace(/\s/gi, "-")
				.replace(/[-]+/gi, "-")

	}
</script>

<style lang="scss">
	@import '../assets/scss/_variables';

	.main-navigation-menu {
		transition: width .5s;

		&:not(.el-menu--collapse) {
			.el-submenu__title, .el-menu-item {
				height: 40px;
				line-height: 40px;
				background-color: transparent !important;
			}

			&:not(.el-menu--horizontal) {
				.el-menu-item, .el-submenu {
					position: relative;

					&::before {
						content: '';
						display: block;
						width: 0px;
						height: 1px;
						position: absolute;
						bottom: 10px;
						left: 30px;
						background: $text-color;
						z-index: 1;
						opacity: 0;
						transition: all .7s cubic-bezier(.55, 0, .1, 1);
					}

					&:hover {
						&::before {
							width: 100px;
							opacity: 1;
							//left: 50px;
							transform: translate(20px, 0);
						}
					}

					&.is-active {
						&::before {
							background: $text-color-accent;
						}
					}
				}
			}

			.el-submenu.is-opened {
				//background: #edf1f6 !important;
				//background: rgba(223, 228, 234, 0.38) !important;
				position: relative;

				&::after {
					content: '';
					display: block;
					width: 2px;
					position: absolute;
					top: 40px;
					bottom: 10px;
					left: 31px;
					background: $text-color;
					z-index: 1;
				}

				&::before {
					display: none;
				}

				.el-menu-item, .el-submenu {
					&::before, &::after {
						display: none;
					}
				}
			}

			.el-menu-item-group__title {
				padding: 15px 0 0px 20px;
				color: transparentize($text-color, 0.65);
			}
		}

		.el-submenu__title, .el-menu-item:not(.is-active) {
			color: $text-color;

			i {
				color: $text-color;
			}
		}

		&.el-menu--collapse {
			.el-menu-item-group__title {
				padding: 15px 0 0px 0px;
				width: 100%;
				text-align: center;
			}

			.el-submenu__title:hover, .el-menu-item:hover {
				background-color: rgba(0, 0, 0, 0.05) !important;
			}
		}

		&.el-menu--horizontal {
			white-space: nowrap;
			/*width: fit-content;
            width: max-content;*/
			overflow: hidden;
			display: table;

			& > * {
				float: inherit !important;
				display: inline-block;
			}
		}

		&.nav-collapsed {
			.el-menu-item, .el-submenu__title {
				& > span {
					display: none;
				}
			}
		}
	}

	.main-navigation-submenu {
		.el-menu {
			background: #fff !important;

			.el-menu-item:not(.is-active) {
				color: $text-color;
			}

			.el-menu-item:hover {
				background-color: transparentize($background-color, 0.3) !important;
			}
		}
	}

	.main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal) .el-submenu {
		position: relative;
		border: none !important;
	}
	.main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal) .el-menu-item, .main-navigation-menu:not(.el-menu--collapse):not(.el-menu--horizontal) .el-submenu {
		position: relative;
		border: none !important;
	}
	@media (min-width: 768px) {
		@import '../assets/scss/_variables';
		.main-navigation-menu {
			transition: width .5s;

			&:not(.el-menu--collapse) {
				.el-submenu__title, .el-menu-item {
					height: 70px;
					line-height: 20px;
					text-align: -webkit-center;
					background-color: transparent !important;
				}
			}
			&.el-menu--horizontal {
				white-space: nowrap;
				overflow: hidden;
				display: table;

				& > * {
					float: inherit !important;
					display: inline-block;
					border-right: 1px solid #dadfe3;
				}
			}
		}
		.el-menu--horizontal > .el-submenu .el-submenu__icon-arrow {
			display: table-cell;
			position: relative;
			right: 3px;
			bottom: 7px;
			vertical-align: middle;
		}
		.el-submenu__title span {
			position: relative !important;
			bottom: 2px !important;
		}
		.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
			position: relative;
			top: 2px;
		}
		.main-navigation-submenu {
			.el-menu {
				background: #e3eaf0 !important;

				.el-menu-item:not(.is-active) {
					color: $text-color;
				}

				.el-menu-item:hover {
					background-color: transparentize($background-color, 0.3) !important;
				}
			}
		}
		.iletisimNav{
			border-bottom-color: transparent;
			background-color: transparent;
			border-top: 5px solid #CE749E;
			--currentColor: #CE749E;
			color: rgb(255, 255, 255);
		}
		.iletisimNav:before {
			content: "";
			position: absolute;
			width: 100%;
			height: 0;
			background: var(--currentColor);
			left: 0;
			top: 0;
			z-index: -1;
			transition: .3s;
		}
		.iletisimNav:hover:before {
			height: 100%;
		}
	}

</style>
<style lang="scss" scoped>
	@import '../assets/scss/_variables';

	.el-menu {
		border: none;
	}

	.el-menu::before, .el-menu::after {
		display: none;
	}

	.el-submenu, .el-menu-item {
		.mdi {
			vertical-align: middle;
			margin-right: 5px;
			display: inline-block;
			width: 24px;
			text-align: center;
			font-size: 18px;
		}
	}
	@media (min-width: 768px) {
		.el-submenu, .el-menu-item {
			.mdi {
				vertical-align: middle;
				position: relative;
				top: 5px;
				display: flex;
				width: 24px;
				text-align: center;
				font-size: 17px;
			}
		}
	}

</style>


<style scoped>
	.main-navigation-menu.el-menu--horizontal > .el-submenu {
		position: relative;
	}
	.main-navigation-menu.el-menu--horizontal > .el-menu-item {
		position: relative;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:before {
		position: absolute;
		display: block !important;
		content: "";
		top: 0;
		left: 0;
		height: 3px;
		width: 100%;
		z-index: 99;
	}
	.main-navigation-menu.el-menu--horizontal > .el-menu-item:before {
		position: absolute;
		display: block !important;
		content: "";
		top: 0;
		left: 0;
		height: 3px;
		width: 100%;
		z-index: 99;
	}

	.main-navigation-menu.el-menu--horizontal > .el-menu-item:before {
		background: #5f9f3c;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(1):before {
		background: #b9ce31;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(2):before {
		background: #aac633;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(3):before {
		background: #9bbe35;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(4):before {
		background: #8cb637;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(5):before {
		background: #7dae39;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(6):before {
		background: #6ea73a;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(7):before {
		background: #5f9f3c;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(8):before {
		background: #50973e;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(9):before {
		background: #418f40;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(10):before {
		background: #328742;
	}

	.main-navigation-menu.el-menu--horizontal > .el-submenu:nth-child(11):before {
		background: #328742;
	}
</style>