import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

var base64 = require('base64_js');
Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({

    state: {
        siparisTur: '',
        sevkiyat: {},
        dil: 'tr',
        odemeDurum: '',
        odemeSekli: '',
        urunList: '',
        guncellenecekMasaId: '',




        layout: {
            navPos: 'left', //top, bottom, left, right, false
            toolbar: 'top', //top, bottom, false
            footer: true, //above, below, false
            boxed: false, //true, false
            roundedCorners: false, //true, false
            viewAnimation: 'fade-top' // fade-left, fade-right, fade-top, fade-top-in-out, fade-bottom, fade-bottom-in-out, fade, false
        },
        splashScreen: true,
        logged: true
    },
    mutations: {
        changeSiparisTur: (state, value) => {
            state.siparisTur = value;
        },
        changeSevkiyat: (state, value) => {
            state.sevkiyat = value;
        },
        changeDil: (state, value) => {
            state.dil = value;
        },
        changeOdemeDurum: (state, value) => {
            state.odemeDurum = value;
        },
        changeOdemeSekli: (state, value) => {
            state.odemeSekli = value;
        },
        changeUrunList: (state, value) => {
            state.urunList = value;
        },
        changeMsiparisId: (state, value) => {
            state.guncellenecekMasaId = value;
        },








        setLayout(state, payload) {
            if (payload && payload.navPos !== undefined)
                state.layout.navPos = payload.navPos

            if (payload && payload.toolbar !== undefined)
                state.layout.toolbar = payload.toolbar

            if (payload && payload.footer !== undefined)
                state.layout.footer = payload.footer

            if (payload && payload.boxed !== undefined)
                state.layout.boxed = payload.boxed

            if (payload && payload.roundedCorners !== undefined)
                state.layout.roundedCorners = payload.roundedCorners

            if (payload && payload.viewAnimation !== undefined)
                state.layout.viewAnimation = payload.viewAnimation
        },
        setLogin(state, payload) {
            state.logged = true
        },
        setLogout(state, payload) {
            state.layout.navPos = null
            state.layout.toolbar = null
            state.logged = false
        },
        setSplashScreen(state, payload) {
            state.splashScreen = payload
        }
    },
    actions: {},
    getters: {
        getTur(state, getters) {
            return state.siparisTur
        },
        getSevkiyat(state, getters) {
            return state.sevkiyat
        },
        getDil(state, getters) {
            return state.dil
        },
        getOdemeDurum(state, getters) {
            return state.odemeDurum
        },
        getOdemeSekli(state, getters) {
            return state.odemeSekli
        },
        getUrunList(state, getters) {
            return state.urunList
        },



        layout(state, getters) {
            return state.layout
        },
        navPos(state, getters) {
            return state.layout.navPos
        },
        toolbar(state, getters) {
            return state.layout.toolbar
        },
        footer(state, getters) {
            return state.layout.footer
        },
        boxed(state, getters) {
            return state.layout.boxed
        },
        roundedCorners(state, getters) {
            return state.layout.roundedCorners
        },
        viewAnimation(state, getters) {
            return state.layout.viewAnimation
        },
        isLogged(state, getters) {
            return state.logged
        },
        splashScreen(state, getters) {
            return state.splashScreen
        }
    },
    plugins: [createPersistedState()],
    strict: debug
})
