<template>
	<div class="layout-container flex justify-center" :class="{
		'column':navPos === 'top' || navPos === 'bottom', 
		'boxed':boxed, 
		'footer-above':footer === 'above',
		'content-only':!navPos
	}">
		<div class="dilSecimiKonum">
			<el-switch
					v-on:change="dilChange"
					style="display: block"
					v-model="dilSecimi"
					active-value="de"
					inactive-value="tr"
					active-color="#fff"
					inactive-color="#fff">
			</el-switch>
		</div>
		<div class="dashboardButonKonum">
			<el-tooltip class="item" effect="dark" content="Dashboard" placement="right">
				<button class="ozelButon yeni card-shadow--medium" @click="dashboardGonder" style="cursor: pointer;width: 60px;height: 60px !important">
					<i class="mdi mdi-home mdi-36px"></i>
				</button>
			</el-tooltip>
		</div>
		<!--<transition name="fade">-->
			<!--<div class="splash-screen" v-if="splashScreen">-->
				<!--<div class="wrap">-->
					<!--<img src="/logo.png" class="logo" alt="logo">-->
					<!--<img src="/Ripple-2s-200px.gif" alt="loading-image">-->
				<!--</div>-->
			<!--</div>-->
		<!--</transition>-->

		<!--<vertical-nav-->
			<!--:position="navPos"-->
			<!--:collapse-nav="collapseNav"-->
			<!--:open-sidebar.sync="openSidebar"-->
			<!--@collapse-nav-toggle="collapseNav = !collapseNav"-->
			<!--@push-page="closeSidebar"-->
			<!--v-if="navPos === 'left'"/>-->

		<div class="container flex column box grow">

			<!--<div class="header" v-if="toolbar === 'top'">-->
				<!--<Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos"/>-->
			<!--</div>-->
			<!--<horizontal-nav :position="navPos" @push-page="closeSidebar" v-if="navPos === 'top'"/>-->
			<!--<div class="header" v-if="toolbar === 'bottom'">-->
				<!--<Toolbar @toggle-sidebar="openSidebar = !openSidebar" :menu-burger="navPos"/>-->
			<!--</div>-->

			<div class="main box grow flex">
				<!--<span class="main-out-border&#45;&#45;top-left" v-if="roundedCorners"></span>-->
				<!--<span class="main-out-border&#45;&#45;top-right" v-if="roundedCorners"></span>-->
				<!--<span class="main-out-border&#45;&#45;bottom-left" v-if="roundedCorners"></span>-->
				<!--<span class="main-out-border&#45;&#45;bottom-right" v-if="roundedCorners"></span>-->
				<transition :name="viewAnimation" mode="out-in">
					<router-view class="view box grow"/>
				</transition>
			</div>

			<!--<horizontal-nav :position="navPos" @push-page="closeSidebar" v-if="navPos === 'bottom'" style="margin-bottom:0;"/>-->
			<!---->
			<!--<Footer v-if="footer === 'below'" :position="footer"/>-->
		</div>

		<!--<vertical-nav-->
			<!--:position="navPos"-->
			<!--:collapse-nav="collapseNav"-->
			<!--:open-sidebar.sync="openSidebar"-->
			<!--@collapse-nav-toggle="collapseNav = !collapseNav"-->
			<!--@push-page="closeSidebar"-->
			<!--v-if="navPos === 'right'"/>-->

		<!--<Footer v-if="footer === 'above'" :position="footer"/>-->

	</div>
</template>


<script>
import { detect } from 'detect-browser'
const browser = detect()
// @ is an alias to /src
import HorizontalNav from '@/core/horizontal-nav.vue'
import VerticalNav from '@/core/vertical-nav.vue'
import Toolbar from '@/core/toolbar.vue'
import Footer from '@/core/footer.vue'

export default {
	name: 'App',
	data() {
		return {
			collapseNav: false,
			openSidebar: false,
			dilSecimi: this.$store.getters.getDil,
			innerWidth: 0
		}
	},
	computed: {
		navPos() {
			if(this.innerWidth <= 768 && (this.$store.getters.navPos === 'top' || this.$store.getters.navPos === 'bottom')) {
				return 'left'
			}
			return this.$store.getters.navPos
		},
		toolbar() {
			return this.$store.getters.toolbar
		},
		footer() {
			return this.$store.getters.footer
		},
		boxed() {
			return this.$store.getters.boxed
		},
		roundedCorners() {
			return this.$store.getters.roundedCorners
		},
		viewAnimation() {
			return this.$store.getters.viewAnimation || 'none'
		},
		isLogged() {
			return this.$store.getters.isLogged
		},
		splashScreen() {
			return this.$store.getters.splashScreen
		}
	},
	methods: {
		resizeOpenNav() {
			this.innerWidth = window.innerWidth
			if(window.innerWidth <= 768) {
				this.collapseNav = false
			}
		},
		closeSidebar() {
			this.openSidebar = false
		},
		dashboardGonder() {
			this.$router.push('./')
		},
		dilChange(val) {
			this.$store.commit('changeDil',val);
			location.reload();
		},
	},
	components: {
		HorizontalNav,
		VerticalNav,
		Toolbar,
		Footer,
	},
	created() {
		if(browser.name)
			document.getElementsByTagName("html")[0].classList.add(browser.name)
	},
	mounted() {
		this.resizeOpenNav()
		window.addEventListener('resize', this.resizeOpenNav);
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.resizeOpenNav);
	}
}
</script>

<style lang="scss">
@import './assets/scss/_variables';
@import './assets/scss/_mixins';


html, body, div, template {
	font-size: 12px !important;
}


.layout-container {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
	background: $background-color;

	.container {
		overflow: hidden;

		.header {
			height: 60px;
			margin-bottom: 20px;
			margin-top: 10px;
			margin-left: 30px;
			margin-right: 30px;
		}

		.main {
			position: relative;
			overflow: hidden;
			padding: 0 30px;
		}

		.view {
			padding: 20px;
			padding-bottom: 10px;
			padding-top: 0px;
			box-sizing: border-box;
			transition: all .4s cubic-bezier(.55,0,.1,1);
			backface-visibility: hidden;
		    /*-webkit-perspective: 1000px;*/

		}
		.fade-top-in-out-enter { opacity: 0; transform: translate(0, 30px); }
		.fade-top-in-out-leave-active { opacity: 0; transform: translate(0, 30px); }

		.fade-top-enter { opacity: 0; transform: translate(0, 30px); }
		.fade-top-leave-active { opacity: 0; transform: translate(0, -30px); }

		.fade-bottom-in-out-enter { opacity: 0; transform: translate(0, -30px); }
		.fade-bottom-in-out-leave-active { opacity: 0; transform: translate(0, -30px); }

		.fade-bottom-enter { opacity: 0; transform: translate(0, -30px); }
		.fade-bottom-leave-active { opacity: 0; transform: translate(0, 30px); }

		.fade-left-enter { opacity: 0; transform: translate(30px, 0); }
		.fade-left-leave-active { opacity: 0; transform: translate(-30px, 0); }

		.fade-right-enter { opacity: 0; transform: translate(-30px, 0); }
		.fade-right-leave-active { opacity: 0; transform: translate(30px, 0); }

		.fade-enter { opacity: 0; }
		.fade-leave-active { opacity: 0; }


		.main-out-border {
			&--top-left, &--top-right {
				background: linear-gradient($background-color, rgba(230,235,241,0));
				height: 16px;
				position: absolute;
				width: 8px;
				z-index: 1;
				top: 4px;
			}
			&--bottom-left, &--bottom-right {
				background: linear-gradient(rgba(230,235,241,0), $background-color);
				height: 16px;
				position: absolute;
				width: 8px;
				z-index: 1;
				bottom: 4px;
			}

			&--top-left, &--bottom-left {
				left: 42px;

				&::after {
					content: "";
					height: 5px;
					position: absolute;
					right: -4px;
					top: -4px;
					width: 12px;
					box-shadow: 8px 0px 0px 0px $background-color inset;
					border-top-left-radius: 5px;
				}
			}
			&--top-right, &--bottom-right {
				right: 42px;

				&::after {
					content: "";
					height: 5px;
					left: -4px;
					position: absolute;
					top: -4px;
					width: 12px;
					box-shadow: -8px 0px 0px 0px $background-color inset;
					border-top-right-radius: 5px;
				}
			}

			&--bottom-left:after {
				border-radius: 0;
				border-bottom-left-radius: 5px;
			}
			&--bottom-right:after {
				border-radius: 0;
				border-bottom-right-radius: 5px;
			}

			&--bottom-left, &--bottom-right {
				&::after {
					top: initial;
					bottom: -4px;
				}
			}
		}

	}

	&.boxed {
		max-width: 1300px;
		margin: 0 auto;
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
	}

	&.footer-above {
		padding-bottom: 40px;
		position: relative;
	}

	&.content-only {
		.container {
			.main-out-border--top-left,.main-out-border--top-right ,
			.main-out-border--bottom-left,.main-out-border--bottom-right {
				display: none;
			}
		}
	}
}

html:not(.ie) {
	.layout-container {
		.container {
			max-width: 1920px;
		}
	}
}

@media (min-width: 1920px) {
	.layout-container:not(.boxed) {
		&.column {
			.container {
				margin: 0 auto;
			}
		}
	}
}

@media (max-width: 768px) {
	.layout-container {
		.container {
			/*width: 100%;
			max-width: 100%;
			height: 100%;
			max-height: 100%;*/

			.header {
				height: 50px;
				background: #fff;
				box-shadow: 0px -20px 10px 20px rgba(0, 0, 0, 0.25);
				margin: 0;
				margin-bottom: 5px;

				.toggle-sidebar {
					box-shadow: none !important;
				}

				.search-box {
					& > .el-autocomplete {
						box-shadow: none !important;
					}
				}
			}

			.main {
				padding: 0 5px;
			}


			.view {
				//padding: 5px;
				max-width: 100%;
				padding: 15px;
				padding-left: 15px;
				padding-right: 15px;
			}
			.main-out-border--top-left,.main-out-border--top-right ,
			.main-out-border--bottom-left,.main-out-border--bottom-right {
				display: none;
			}
		}
	}
}

.el-autocomplete-suggestion li {
	display: flex;
	align-items: center;
	padding: 0 10px 0 5px;
	line-height: normal;
	height: 42px;
}

.search-box-popper .value {
	white-space: normal;
	margin-left: 5px;
}

.el-autocomplete-suggestion.is-loading li {
	justify-content: center;
}


.el-badge__content.is-fixed {
	top: -3px;
}

.layout-container .container .view:not(.login-page) {
	margin-bottom: 50px;
}

.fade-enter-active, .fade-leave-active {
	transition: opacity 0.5s ease-out;
}
.fade-enter, .fade-leave-to {
	opacity: 0;
}

.sayfa404 .main {
	padding: 0 !important;
}

.el-input-number--mini {
	width: 117px;
}

img[data-zoom] {
	cursor: crosshair;
}

@media (max-width: 480px) {
	.el-select-dropdown {
		max-width: 340px;
	}
}



.inputAlani {
	padding:15px !important;
}
.logoAlani {
	padding:15px !important;
}


.ortaLogo {
	max-width: 250px;
	margin: 0;
	padding: 0;
}

.ozelButon:focus,
.ozelButon:active,
.ozelButon:hover {
	color: #4a596a !important;
}


.ozelButon.yeni:focus,
.ozelButon.yeni:active,
.ozelButon.yeni:hover {
	background: #4a596a !important;
	color: #ffffff !important;
	border-color: #4a596a !important;
}

.ozelButon.yeni {
	background: #ffffff!important;
	color: #4a596a !important;
	border: 2px solid #4a596a;
}

::selection {
	background: #4a596a !important;
	color: #ffffff !important;
}

.ozelButon {
	border: none ;
	padding: 30px;
	height: auto !important;
	width: 100%;
	text-align: center;
	font-size: 20px;
	text-transform: uppercase;
	font-weight: bold;
	border-radius: 5px;
}

.fatihCol {
	padding: 0 !important;
	box-shadow: none;
	border-radius: 5px;
}

.el-input--mega .el-input__inner {
	font-size: 24px;
	height: 55px;
	line-height: 55px;
	box-shadow: 0 0 6px 0 rgba(40, 40, 90, .09), 0 1px 1px 0 rgba(0, 0, 0, .07);
}

.el-input--mega .el-input__inner:focus {
	border-color: #4a596a;
}

.el-input--mega .el-input__inner::placeholder {
	color: #7b95a7;
}

.vfl-has-label.styled .vfl-label.vfl-label-on-input {
	color: #4a596a;
	width: auto;
}

.ileriGeriButonlar {
	position: absolute;
	width: 100vw;
	top: 30px;
	left: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
}


.geriButon {
	background: #4a596a !important;
	color: #ffffff !important;
	border: 2px solid #4a596a;
	font-size: 24px;
	padding: 15px 20px;
	min-width: 140px;
	font-weight: bold;
	box-shadow: 0 0 6px 0 rgba(40, 40, 90, .09), 0 1px 1px 0 rgba(0, 0, 0, .07);
}

.geriButon span {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.geriButon:focus,
.geriButon:active,
.geriButon:hover {
	background: #ffffff !important;
	color: #4a596a !important;
	border-color: #ffffff !important;
}

.inputAlani .el-radio.is-bordered{
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	padding: 0;
}

.kadin{
	color: deeppink;
}

.erkek{
	color: navy;
}

.sagIcon {
	position: absolute;
	right: 0;
	top: 0;
	height: 100%;
	width: 80px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center
}

.sagIcon::before {
	content: "";
	position: absolute;
	left: 0;
	top: 10px;
	height: calc(100% - 20px);
	background: #4a596a;
	width: 1px;
	opacity: .3;
}

.login-page {
	background: #4a596a;
	background-size: 100% 100% !important;
	background-repeat: no-repeat !important;
}

	@media (max-width: 480px) {
		.font-size-20 {
			font-size: 16px;
		}
		.login-page {
			background-size: cover !important;
		}
	}
/**/
@function gradientOlustur($ilkRenk, $ikinciRenk, $ucuncuRenk, $or: 90deg){
	@return linear-gradient($or, $ilkRenk 33.3%, $ikinciRenk 33.3%, $ikinciRenk 66.6%, $ucuncuRenk 66.6%);
}

	.dilSecimiKonum {
		position: fixed;
		top: 5px;
		right: 5px;
		z-index: 99;
		.el-switch__core {
			height: 30px;
			width: 60px !important;
			border-radius: 15px;
			&:after {
				width: 24px;
				height: 24px;
				background-color: #E30A17;
				background-image: url("assets/images/tr.png");
				background-size: 20px 16px;
				background-position: center center;
				background-repeat: no-repeat;
			}
		}
		.el-switch.is-checked .el-switch__core::after {
			margin-left: -25px;
			background: gradientOlustur(black, #D00, #FFCE00, -180deg);
		}
	}

	.dashboardButonKonum {
		position: fixed;
		top: 2px;
		left: 5px;
		z-index: 99;
		button {
			padding: 0;
			color: #E30A17;
			&:focus,
			&:active,
			&:hover {
				color: #E30A17;
			}
		}
	}
</style>
