import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');
var userDataKaderFoodKasa = localStorage.getItem("userDataKaderFoodKasa") ? JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa")))) : "";

var serviceName = "index";
var urunService = {
    imageUrlBuyuk: WsProvider.resim + "/" + "HBT0082" + "/buyuk/",
    imageUrlOrta: WsProvider.resim + "/" + "HBT0082" + "/orta/",
    imageUrlKucuk: WsProvider.resim + "/" + "HBT0082" + "/kucuk/",
    imageUrlEnKucuk: WsProvider.resim + "/" + "HBT0082" + "/enkucuk/",
    path  : "https://www.kaderfood.org.tr/",
    kategoriTree() {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                serviceName: "urun",
                methodName: "urunKategoriTree"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    ukategoriList(dil) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                durum: 1,
                dil: dil,
                serviceName: "urun",
                methodName: "ukategoriList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    ukategoriDetay(ukategoriId ) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                ukategoriId : ukategoriId ,
                serviceName: "urun",
                methodName: "ukategoriDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    urunCount(durum) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                durum: durum,
                serviceName: "urun",
                methodName: "urunCount"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    urunDetay(urunId,dil) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                urunId: urunId,
                dil: dil,
                serviceName: "urun",
                methodName: "urunDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    urunListesi(durum,baslangic,limit) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                durum: durum,
                dil: [],
                baslangic: baslangic,
                limit: limit,
                serviceName: "urun",
                methodName: "urunTumListesi"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    kategoriUrunList(ukategoriId,dil) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                ukategoriId : ukategoriId,
                dil : dil,
                serviceName: "urun",
                methodName: "kategoriUrunList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    uopsiyonList(durum,baslangic,limit) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                dil : "",
                durum : durum,
                baslangic : baslangic,
                limit : limit,
                serviceName: "urun",
                methodName: "uopsiyonList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    uopsiyonDetay(uopsiyonId) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                uopsiyonId: uopsiyonId,
                serviceName: "urun",
                methodName: "uopsiyonDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    umalzemeList(durum,baslangic,limit) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                dil : "",
                durum : durum,
                baslangic : baslangic,
                limit : limit,
                serviceName: "urun",
                methodName: "umalzemeList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    umalzemeDetay(umalzemeId) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                umalzemeId: umalzemeId,
                serviceName: "urun",
                methodName: "umalzemeDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    ukategoriOpsiyonList(ukategoriId) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                ukategoriId: ukategoriId,
                serviceName: "urun",
                methodName: "ukategoriOpsiyonList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    ukategoriMalzemeList(ukategoriId,dil) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                ukategoriId: ukategoriId,
                dil: dil,
                serviceName: "urun",
                methodName: "ukategoriMalzemeList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

}

export default urunService

