import Vue from 'vue'
import Router from 'vue-router'

//apps
import Dashboard from '../views/apps/Dashboard.vue'

//pages
import Login from '../views/pages/authentication/Login.vue'
import Login2 from '../views/pages/authentication/Login2.vue'
import Register from '../views/pages/authentication/Register.vue'
import ForgotPassword from '../views/pages/authentication/ForgotPassword.vue'
import NotFound from '../views/pages/NotFound.vue'
import Invoice from '../views/pages/Invoice.vue'

//ui
import Siparis from "../components/Siparis";
import Sevkiyat from "../components/Sevkiyat";
import Masa from "../components/Masa";
import RestoranIc from "../components/RestoranIc";
import Raporlar from "../components/Raporlar";
import SiparisGuncelle from "../components/SiparisGuncelle";


import layouts from '../layout'
import store from '../store'

Vue.use(Router)


const router = new Router({
	mode: 'history',
	base: '/',
	routes: [
		{
			path: '/',
			alias: '/dashboard',
			name: 'dashboard',
			component: Dashboard,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				tags: ['app']
			}
		},
		{
			path: '/login',
			name: 'login',
			component: Login,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		},
		{
			path: '/siparis',
			name: 'siparis',
			component: Siparis,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		},
		{
			path: '/SiparisGuncelle',
			name: 'siparisguncelle',
			component: SiparisGuncelle,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		},
		{
			path: '/raporlar',
			name: 'raporlar',
			component: Raporlar,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		},
		{
			path: '/sevkiyat',
			name: 'sevkiyat',
			component: Sevkiyat,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		},
		{
			path: '/masa',
			name: 'masa',
			component: Masa,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		},
		{
			path: '/restoran',
			name: 'restoran',
			component: RestoranIc,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		},
		{
			path: '/register',
			name: 'register',
			component: Register,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		},
		{
			path: '/sifremi-unuttum',
			name: 'sifremi-unuttum',
			component: ForgotPassword,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		},
		{
			path: '/logout',
			beforeEnter(to, from, next) {
				localStorage.removeItem("userDataKaderFoodKasa");
				auth.logout()
				next({path: '/login'})
			}
		},
		{
			path: '*',
			name: 'not-found',
			component: NotFound,
			meta: {
				auth: true,
				layout: layouts.navLeft,
				searchable: true,
				title: 'eCommerce admin dashboard',
				tags: ['app', 'Ecommerce']
			}
		}
	]
})


const l = {
	contenOnly() {
		store.commit('setLayout', layouts.contenOnly)
	},
	navLeft() {
		store.commit('setLayout', layouts.navLeft)
	},
	navRight() {
		store.commit('setLayout', layouts.navRight)
	},
	navTop() {
		store.commit('setLayout', layouts.navTop)
	},
	navBottom() {
		store.commit('setLayout', layouts.navBottom)
	},
	set(layout) {
		store.commit('setLayout', layout)
	}
}

//insert here login logic
const auth = {
	loggedIn() {
		return store.getters.isLogged
	},
	logout() {
		store.commit('setLogout')
	}
}

router.beforeEach((to, from, next) => {
	let authrequired = false
	if (to && to.meta && to.meta.auth)
		authrequired = true
	if (authrequired) {
		if (auth.loggedIn()) {
			if (to.name === 'login') {
				window.location.href = '/'
				return false
			} else {
				next()
			}
		} else {
			if (to.name !== 'login') {
				window.location.href = '/login'
				return false
			}
			next()
		}
	} else {
		if (auth.loggedIn() && to.name === 'login') {
			window.location.href = '/'
			return false
		} else {
			next()
		}
	}

	if (to && to.meta && to.meta.layout) {
		l.set(to.meta.layout)
	}
})

router.afterEach((to, from) => {
	setTimeout(() => {
		store.commit('setSplashScreen', false)
	}, 500)
})

export default router