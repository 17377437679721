import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');
var userDataKaderFoodKasa = localStorage.getItem("userDataKaderFoodKasa") ? JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa")))) : "";
var serviceName = "controller";
var controllerService = {
    imageUrlBuyuk: WsProvider.resim+"/"+"HBT0082"+"/buyuk/",
    imageUrlOrta: WsProvider.resim+"/"+"HBT0082"+"/orta/",
    imageUrlKucuk: WsProvider.resim+"/"+"HBT0082"+"/kucuk/",
    resimYukle(modul,name, base) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                musteriId: userDataKaderFoodKasa["musteriId"],
                musteriToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                modul: modul,
                name: name,
                base: base,
                methodName: "resimyukle",
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sunucuResim(modul) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                musteriId: userDataKaderFoodKasa["musteriId"],
                musteriToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                modul: modul,
                methodName: "sunucuResim",
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    belgeYukle(modul,name, base) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                musteriId: userDataKaderFoodKasa["musteriId"],
                musteriToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                modul: modul,
                name: name,
                base: base,
                methodName: "belgeyukle",
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    sunucuBelge(modul) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                musteriId: userDataKaderFoodKasa["musteriId"],
                musteriToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                modul: modul,
                methodName: "sunucuBelge",
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    }
};
export default controllerService



