<template>
    <div class="giris">
        <el-row>
            <el-col :span="20" :offset="2">
                <div class="girisCard">
                    <el-row>
                        <el-col :span="10" :offset="1">
                            <el-card shadow="hover" style="cursor: pointer" @click.native="siparisStart">
                                <div class="card1Bg">
                                    <div class="girisBtnBg">
                                        <el-button>{{$t("siparis.yeniSiparis")}}</el-button>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                        <el-col :span="10" :offset="1" style="cursor: pointer" @click.native="siparisStart1">
                            <el-card shadow="hover">
                                <div class="card1Bg">
                                    <div class="girisBtnBg">
                                        <el-button>{{$t("masa.listeBaslik")}}</el-button>
                                    </div>
                                </div>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>

    var base64 = require('base64_js');
    export default {
        name: 'RestoranIc',
        data() {
            return {}
        },
        computed: {},
        methods: {
            siparisStart() {
                var item = {
                    adres: '',
                    adSoyad: '',
                    telefon: '',
                };
                // console.log(item)
                this.$store.commit('changeSiparisTur', 0);
                this.$store.commit('changeSevkiyat', item);
                this.$router.push({name: 'siparis'})
            },
            siparisStart1() {
                this.$router.push({name: 'masa'})
            }
        },
    }


</script>

<style lang="scss" scoped>
    @import '../assets/scss/_variables';

    .giris {
        background-image: url("../assets/images/katman/giris-bg.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;

    }

    .girisCard {
        margin: 5% 10%;
        position: relative;


        .el-card {
            border-radius: 18px;
            border: 1px solid #EBEEF5;
            background-color: #ffffff6e;
            overflow: hidden;
            color: #303133;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            position: relative;

            .girisBtnBg {
                position: absolute;
                background-color: #ffffff6e;
                border-radius: 10px;
                bottom: 50px;
                left: 50%;
                transform: translateX(-50%);
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                .el-button {
                    margin: 10px;
                    display: inline-block;
                    line-height: 1;
                    white-space: nowrap;
                    cursor: pointer;
                    background: #FFFFFF;
                    border: 1px solid #DCDFE6;
                    border-color: #DCDFE6;
                    color: #606266;
                    -webkit-appearance: none;
                    text-align: center;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    outline: none;
                    -webkit-transition: .1s;
                    transition: .1s;
                    font-weight: 500;
                    -moz-user-select: none;
                    -webkit-user-select: none;
                    -ms-user-select: none;
                    padding: 15px 60px;
                    font-size: 14px;
                    border-radius: 10px;
                }
            }

        }

    }

    .card1Bg {
        background-image: url('../assets/images/katman/giris1.jpg');
        background-position-x: -220px;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 18px;
        height: 80vh;
    }

    .card2Bg {
        background: url('../assets/images/katman/giris2.jpg') center center;
        background-size: cover;
        border-radius: 18px;
        height: 80vh;
    }


    @media (max-width: 1400px) {
        .giris {

            .list {
                .item {
                    width: 33.33%;
                }
            }
        }
    }


    @media (max-width: 1100px) {
        .giris {

            .list {
                .item {
                    width: 50%;
                }
            }
        }
    }


    @media (max-width: 900px) {
        .giris {


        }
    }

    @media (max-width: 768px) {
        .giris {


        }
    }

    @media (max-width: 480px) {
        .giris {


        }
    }

</style>