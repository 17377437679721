<template>
    <el-dialog :custom-class="'user-dialog'" :visible="dialogvisible" width="60%" center
               @close="$emit('update:dialogvisible', false)">
        <div class="urunImg"></div>
        <div class="center demo-box">
            <div class="right-box box grow text-center padding-20">
                <span class="urunAdi">{{urunBilgisi.baslik}}</span><br>
            </div>
            <div class="right-box box grow text-center">

                <el-tag
                        v-for="tag in urunBilgisi.malzeme"
                        :class="{ disabledUrun: cikarilanUrunler.includes(tag)}"
                        class="margin-5"
                        style="cursor: pointer"
                        :key="tag.name"
                        effect="dark"
                        v-on:click="malzemeCikar(tag)"
                        type="info">
                    <span>{{tag.baslik}}</span>
                </el-tag>
                <hr>
                <div>
                    <el-row :gutter="10" class="padding-horizontal-10">
                        <el-col :span="4" v-for="i in kategoriMalzemeListesi">
                            <div class="ekUrun" :class="{ activeEkUrun: arrayEkUrun.includes(i) }">
                                <div v-if="i.fiyat !== ''" class="ekUrunFiyat">{{i.fiyat}}</div>
                                <div v-else class="ekUrunFiyat">0</div>
                                <el-card class="urunDetayDialog" @click.native="ekUrunSec(i)">
                                    <span>{{ i.baslik }}</span>
                                </el-card>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div class="sepeteEkle">
                    <el-button type="info" v-on:click="sepeteAt" style="text-transform: uppercase">{{$t('siparis.sepeteEkle')}}</el-button>
                </div>
            </div>
        </div>

    </el-dialog>
</template>

<script>
    import urunService from '../WSProvider/UrunService'
    // import Password from 'vue-password-strength-meter'
    // import status from '../s'

    let base64 = require('base64_js');
    export default {
        name: 'UrunDetay',
        props: ['urundata', 'dialogvisible'],
        watch: {
            urundata(val) {
                this.urunDetay(val);
                this.kategoriMalzemeList(val);
            },
            dialogvisible(val) {
                if (!val) {
                    this.malzemeList = [];
                    this.cikarilanUrunler = [];
                    this.arrayEkUrun = [];
                }
            },
        },
        data() {
            return {
                malzemeList: [],
                siparisList: [],
                kategoriMalzemeListesi: [],

                urunBilgisi: [],
                arrayEkUrun: [],
                cikarilanUrunler: [],
            }

        },
        mounted() {
        },
        methods: {
            urunDetay(val) {
                urunService.urunDetay(val.urunId,this.$store.getters.getDil).then((response) => {
                    if (response.status === 200) {
                        this.urunBilgisi = response.data
                    }
                })

            },
            kategoriMalzemeList(val) {

                urunService.ukategoriMalzemeList(val.ukategoriId,this.$store.getters.getDil).then((response) => {
                    if (response.status === 200) {
                        this.kategoriMalzemeListesi = response.data
                    }
                })

            },


            ekUrunSec(val) {
                var obj = this.arrayEkUrun.find(data => data === val);
                var obj1 = this.malzemeList.find(data => data.umalzemeId === val.umalzemeId);
                if (obj === undefined) {
                    this.arrayEkUrun.push(val)
                    this.malzemeList.push({
                        durum: 1,
                        umalzemeId: val.umalzemeId,
                        baslik: val.baslik,
                        fiyat: val.fiyat,
                    })
                } else {
                    this.arrayEkUrun.splice(this.arrayEkUrun.indexOf(val), 1)
                    this.malzemeList.splice(this.malzemeList.indexOf(obj1), 1)
                }
            },
            malzemeCikar(val) {
                var obj = this.cikarilanUrunler.find(data => data === val);
                var obj1 = this.malzemeList.find(data => data.umalzemeId === val.umalzemeId);
                if (obj === undefined) {
                    this.cikarilanUrunler.push(val)
                    this.malzemeList.push({
                        durum: 0,
                        umalzemeId: val.umalzemeId,
                        baslik: val.baslik,
                    })
                } else {
                    this.cikarilanUrunler.splice(this.cikarilanUrunler.indexOf(val), 1)
                    this.malzemeList.splice(this.malzemeList.indexOf(obj1), 1)
                }

            },
            sepeteAt() {
                var item = {
                    urunId: this.urunBilgisi.urunId,
                    baslik: this.urunBilgisi.baslik,
                    fiyat: this.urunBilgisi.fiyat,
                    adet: 1,
                    malzemeList: this.malzemeList
                };
                this.$emit('sepeteAt', item);
                this.$emit('update:dialogvisible', false);
            }
        }
    }
</script>

<style lang="scss">
    @import '../assets/scss/_variables';


    .form-box .el-form div {
        margin: 20px 0;
    }

    .form-box .el-form i {
        font-size: 20px;
    }

    .el-dialog.user-dialog {
        border-radius: 30px;

        .el-dialog__header {
            padding: 0;

            .el-dialog__headerbtn {
                z-index: 1;
                background: white !important;
                min-width: 20px;
                min-height: 20px;
                border-radius: 50%;
                position: absolute;
                top: -3px;
                left: -3px;
                padding: 0;
                border: none;
                outline: none;
                cursor: pointer;
                font-size: 16px;
                box-shadow: 0 0 1px 3px rgba(180, 180, 180, 0.4);
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                .el-dialog__close {
                    font-weight: bold;
                    color: #000000;
                }
            }
        }

        .el-dialog__body {
            padding: 0;
        }

        .urunImg {
            background: url("../assets/images/katman/pizza_fresca.jpg") center center;
            margin: 20px !important;
            background-size: cover;
            height: 150px;
            position: relative;
            top: 20px;
            border-radius: 50px 50px 0 0;
            box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.2);


            img {
                width: 100px;
                height: 100px;
                border-top-left-radius: 50%;
                border-top-right-radius: 50%;
                position: relative;
                bottom: -100px;
                display: block;
                padding: 5px;
                margin: 0 auto;
                background: white;
                box-sizing: border-box;
            }

        }

        .form-box {
            padding: 20px;
            box-sizing: border-box;

            & > * {
                margin: 10px 0;
            }
        }

        .urunAdi {
            font-weight: bold;
            font-size: 24px;
            color: #bc1722;
            text-transform: uppercase;
        }
    }

    .ekUrun {
        position: relative;


        .el-card {
            border-radius: 0;
            cursor: pointer;
            margin: 10px 0;
        }

        span {
            font-size: 16px;
            font-weight: bold;
            display: inline-block;
            word-break: break-word;
        }

        .ekUrunFiyat {
            height: 25px;
            width: 45px;
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            background: #bc1722;
            box-shadow: 0 0 0 2px rgba(188, 23, 24, 0.4);
            color: #fff;
            /*border-radius: 50%;*/
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px !important;
            transition: 300ms all ease-in-out;

        }

    }

    .activeEkUrun {
        .el-card {
            background: #bc1722;
            color: #fff;
        }

        .ekUrunFiyat {
            box-shadow: 0 0 0 3px rgba(255, 255, 255, 0.8);
        }
    }

    .disabledUrun {
        opacity: 0.6 !important;

        span {
            text-decoration: line-through !important;
        }
    }

    .sepeteEkle {
        .el-button {
            border: none;
            background: #4f5568;
            width: 60%;
            border-radius: 0 !important;
            box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.4);
            font-weight: bold;
            position: relative;
            top: 20px;
        }
    }


    .urunDetayDialog {
        .el-card__body {
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
        }
    }
</style>
