import JQuery from 'jquery';

var base64 = require('base64_js');
let $ = JQuery;
var url = "https://www.kaderfood.ch/kaderfood-api/view/";

var WSProvider = {
    resim: url+"../../resimler",
    ajaxPost: function (serviceName, jsonParams) {
        var service = new Promise(function (resolve, reject) {
            return $.ajax({
                type: 'POST',
                url: url + serviceName + ".php",
                datatype: 'application/json',
                headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                data: jsonParams,
                success: function (result) {
                    if (result) {
                        resolve(JSON.parse(result))
                    } else {
                        resolve()
                    }
                },
                error: function (request) {
                    console.log(request)
                }
            })
        })
        return service
    },
    ajaxGet: function (serviceName, methodName, jsonParams) {
        var service = new Promise(function (resolve, reject) {
            return $.ajax({
                type: 'GET',
                url: url + serviceName + ".php",
                data: {
                    "data": base64.encode(encodeURI(JSON.stringify(jsonParams)))
                },
                headers: {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'},
                enctype: "application/json",
                datatype: 'json',
                success: function (result) {
                    resolve(result)
                },
                error: function (request) {
                    resolve(false)
                }
            })
        })
        return service
    },
}
export default WSProvider
