import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');

var userDataKader = localStorage.getItem("userDataKader") ? JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKader")))) : "";
var serviceName = "yonetim";
var crypto = require('crypto');
var yoneticiService = {
    imageUrlBuyuk: WsProvider.resim + "/" + userDataKader["firmaKodu"] + "/buyuk/",
    imageUrlOrta: WsProvider.resim + "/" + userDataKader["firmaKodu"] + "/orta/",
    imageUrlKucuk: WsProvider.resim + "/" + userDataKader["firmaKodu"] + "/kucuk/",
    imageUrlEnKucuk: WsProvider.resim + "/" + userDataKader["firmaKodu"] + "/enkucuk/",

    girisYap(kullaniciAdi, sifre) {
        var param = {
            data: {
                kullaniciAdi: kullaniciAdi,
                sifre: crypto.createHash('md5').update(sifre).digest("hex"),
                methodName: "login"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    sifremiUnuttum(kullaniciAdi, tcNo) {
        var param = {
            data: {
                kullaniciAdi: kullaniciAdi,
                tcNo: tcNo,
                methodName: "sifremiUnuttum"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    sifreGuncelle(eskiSifre, yeniSifre) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
var param = {
            data: {
                yonetimId: userDataKader["yonetimId"],
                userToken: userDataKader["token"],
                firmaKodu: userDataKader["firmaKodu"],
                yoneticiId: userDataKader["yonetimId"],
                eskiSifre: crypto.createHash('md5').update(eskiSifre).digest("hex"),
                yeniSifre: crypto.createHash('md5').update(yeniSifre).digest("hex"),
                methodName: "sifremiGuncelle"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    yoneticiTanimlama(tur, tcNo, image, kullaniciAdi, sifre, adSoyad, gorev, eposta, cepTelefon, sabitTelefon, dahili) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
var param = {
            data: {
                yonetimId: userDataKader["yonetimId"],
                userToken: userDataKader["token"],
                firmaKodu: userDataKader["firmaKodu"],
                tur: tur, // 2: tam yetkili, 3:sınırlı yetki
                tcNo: tcNo,
                image: image,
                kullaniciAdi: kullaniciAdi,
                sifre: crypto.createHash('md5').update(sifre).digest("hex"),
                adSoyad: adSoyad,
                gorev: gorev,
                eposta: eposta,
                cepTelefon: cepTelefon,
                sabitTelefon: sabitTelefon,
                dahili: dahili,
                methodName: "yoneticiTanimlama"
            }
        };
        var result = new Promise(function (resolve) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    yoneticiList() {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
var param = {
            data: {
                yonetimId: userDataKader["yonetimId"],
                userToken: userDataKader["token"],
                firmaKodu: userDataKader["firmaKodu"],
                methodName: "yonetimListesi"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    yoneticiDetay(yetkiliKodu) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
var param = {
            data: {
                yonetimId: userDataKader["yonetimId"],
                userToken: userDataKader["token"],
                yetkiliKodu: yetkiliKodu,
                firmaKodu: userDataKader["firmaKodu"],
                methodName: "yonetimDetay"
            }
        };
        var result = new Promise(function (resolve) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    yoneticiDurumGuncelle(yetkiliKodu, durum) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
var param = {
            data: {
                yonetimId: userDataKader["yonetimId"],
                userToken: userDataKader["token"],
                firmaKodu: userDataKader["firmaKodu"],
                yetkiliKodu: yetkiliKodu,
                durum: durum,
                methodName: "yonetimDurumGuncelle"
            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },

    yoneticiGuncelle(yetkiliKodu, updateList) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
var param = {
            data: {
                yonetimId: userDataKader["yonetimId"],
                userToken: userDataKader["token"],
                firmaKodu: userDataKader["firmaKodu"],
                yetkiliKodu: yetkiliKodu,
                updateList: updateList,
                methodName: "yonetimGuncelle"
            }
        }
        var result = new Promise(function (resolve) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    yetkiList() {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
var param = {
            data: {
                yonetimId: userDataKader["yonetimId"],
                userToken: userDataKader["token"],
                firmaKodu: userDataKader["firmaKodu"],
                methodName: "yetkiList"
            }
        }
        var result = new Promise(function (resolve) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    },
    yetkiAtama(yetkiliKodu, yetkiKodu,durum) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
var param = {
            data: {
                yonetimId: userDataKader["yonetimId"],
                userToken: userDataKader["token"],
                firmaKodu: userDataKader["firmaKodu"],
                yetkiliKodu:yetkiliKodu,
                yetkiKodu:yetkiKodu,
                durum:durum,
                methodName: "yetkiAtama"
            }
        }
        var result = new Promise(function (resolve) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;
    }
};
export default yoneticiService



