<template>
    <div>
        <el-row>
            <el-col :span="20" :offset="2">
                <div class="grid-content bg-purple">
                    <p>{{$t("siparis.baslik")}}</p>
                </div>
                <div class="margin-top-20">
                    <el-row :gutter="20">
                        <el-col :span="8" class="scrollable only-y" style="max-height: calc(100vh - 100px)">
                            <div class="card-shadow--medium fisAlani">
                                <el-row class="ozelBorder">
                                    <el-col :span="16" class="ozelPadding borderRight solBosluk boldYazi fonts-16">{{$t("siparis.urunAdi")}}</el-col>
                                    <el-col :span="4" class="ozelPadding borderRight ortala boldYazi fonts-16">{{$t("siparis.miktar")}}</el-col>
                                    <el-col :span="4" class="ozelPadding ortala boldYazi sag fonts-16">{{$t("siparis.tutar")}}</el-col>
                                </el-row>
                                <div v-for="(item,index) in secilenUrunler" :key="index" class="icRowDiv">
                                    <el-row class="icRow">
                                        <el-col :span="2" class="ozelPadding">
                                            <el-tooltip content='Sil'
                                                        :open-delay="500"
                                                        placement="bottom">
                                                <el-button
                                                        v-on:click="removeUrun(item,index)"
                                                        type="text"
                                                        style="color:#bc1722 "
                                                        icon="mdi mdi-delete-circle mdi-18px">
                                                </el-button>
                                            </el-tooltip>
                                        </el-col>
                                        <el-col :span="14" class="ozelPadding borderRight fonts-16">{{item.baslik}}</el-col>
                                        <el-col :span="4" class="ozelPadding borderRight ortala fonts-16">{{item.adet}}</el-col>
                                        <el-col :span="4" class="ozelPadding ortala sag fonts-16">{{item.fiyat}}</el-col>
                                        <el-col :span="24" v-if="item.malzemeList.length>0">
                                            <div class="boldYazi ekYer" style="background: #f1f1f1">
                                                <div v-for="i in item.malzemeList">
                                                    <span v-if="i.durum === 0 " class="fonts-14">
                                                        {{i.baslik}}
                                                        <div class="fiyatBilgisi">
                                                           <i style="color: red" class="mdi mdi-close"></i>
                                                        </div>
                                                    </span>
                                                    <span v-else>
                                                        {{i.baslik}}
                                                        <div class="fiyatBilgisi fonts-14">
                                                           {{i.fiyat !== 0 ? i.fiyat : ' - '}}
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </el-col>
                                    </el-row>
                                </div>
                                <el-col :span="24" class="ozelBorder" style="background-color: #fff">
                                    <div class="ozelPadding altCizgi" style="justify-content: space-between">
                                        <div class="fonts-16"> {{$t("siparis.siparisOzeti")}}:</div>
                                        <div class="fonts-16">{{adet_text}} {{$t("siparis.urun")}}</div>
                                    </div>

                                    <div class="ozelPaddingv2">
                                        <div class="ortaYazi">{{$t("siparis.tutar")}}</div>
                                        <div class="ortaYazi boldYazi">{{amount_text_kasa}}</div>
                                    </div>
                                    <div class="ozelPaddingv2">
                                        <div class="ortaYazi">{{$t("siparis.toplamVergi")}} ({{this.vergiMiktari}}%)</div>
                                        <div class="ortaYazi boldYazi">{{amount_text_vergi}}</div>
                                    </div>
                                </el-col>

                                <el-col :span="24" style="border: 1px solid #4b5264;border-top: 0; background-color: #ffffff" class="toplamBolgesi">
                                    <div class="ozelPaddingv2">
                                        <span class="ortaYazi">{{$t("siparis.toplam")}} <br> {{$t("siparis.tutar")}}</span>
                                        <span class="sonTutar">{{amount_text}}</span>
                                    </div>
                                </el-col>
                            </div>

<!--                            <el-col :lg="24" :md="24" :sm="24" :xs="24" style="padding: 0; margin: 15px 0">-->
<!--                                <el-radio-group class="odemeRadio" v-model="odemeSekli">-->
<!--                                    <el-radio-button label="1">{{$t("siparis.nakit")}}</el-radio-button>-->
<!--                                    <el-radio-button label="2">{{$t("siparis.kart")}}</el-radio-button>-->
<!--                                    <el-radio-button label="3">{{$t("siparis.ucretsiz")}}</el-radio-button>-->
<!--                                </el-radio-group>-->
<!--                            </el-col>-->
<!--                            <el-col :span="24" style="padding: 0 !important;margin-top: 15px">-->
<!--                                <el-button :class="{ activeOdeme: odemeDurumu === 1 }" class="card-shadow&#45;&#45;medium butonAyarlari odemeAlindi" size="mini" type="success" @click="odemeDurumuChange">-->
<!--                                    <i class="mdi mdi-check-circle-outline"></i> {{$t("siparis.odemeAlindi")}}-->
<!--                                </el-button>-->
<!--                            </el-col>-->

                            <el-col :span="24" style="padding: 0 !important;margin-top: 15px">
                                <el-button size="mini" @click="sipisTamamla" class="odemeButon">
                                    {{$t("siparis.siparisTamamla")}}
                                </el-button>
                            </el-col>

<!--                            <el-col v-if="odemeSekli ==='3'" :span="24" style="padding: 0 !important;margin-top: 35px">-->
<!--                                <el-button size="mini" @click="fisCikar" class="odemeButon">-->
<!--                                    {{$t("siparis.yazdir")}}-->
<!--                                </el-button>-->
<!--                            </el-col>-->

                        </el-col>
                        <el-col :span="4" class="scrollable only-y" style="max-height: calc(100vh - 100px)">
                            <div class="kategori" v-for="i in kategoriList" v-on:click="kategoriSecim(i.ukategoriId)" :class="{ activeKat: arrayKat.includes(i.ukategoriId) }">
                                <img src="../assets/images/katman/defaulturun-kucuk.jpg" alt="" class=""/>
                                <div class="kategoriRenk" :style="'background:'+i.renk"></div>
                                <div class="kategoriDetay">
                                    <div>
                                        <span class="font-weight-700" style="display: block; text-transform: uppercase">
                                            {{baslik =  i.baslik }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12" class="scrollable only-y" style="max-height: calc(100vh - 100px)">
                            <el-card shadow="always">
                                <el-row :gutter="20">
                                    <el-col :span="8" v-for="i in kategoriUrunList">
                                        <div class="urun" v-on:click="urunSecim(i)" :class="{ activeurun: arrayUrun.includes(i) }">
                                            <img v-if="i.resim === null" src="../assets/images/katman/defaulturun-kucuk.jpg" alt="" class=""/>
                                            <img v-else :src="path + i.resim" alt="" class=""/>
                                            <div class="urunDetay">
                                                <div>
                                                    <span class="font-weight-700" style="display: block;">
                                                        {{baslik =  i.baslik }}
                                                    </span>
                                                    <span>{{i.fiyat}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-card>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
        <urun-detayi :urundata="urunData" :dialogvisible.sync="urunDetayVisible" v-on:sepeteAt="sepeteAt"></urun-detayi>
    </div>
</template>

<script>
    import EventBus from '@/components/event-bus'
    import UrunDetayi from './UrunDetayi'
    import urunService from "../WSProvider/UrunService";
    import musteriService from "../WSProvider/MusteriService";
    import notification from "../notification";
    import moment from 'moment-timezone'

    import JQuery from 'jquery'

    let $ = JQuery;
    let base64 = require('base64_js');

    export default {
        name: "SiparisGuncelle",
        components: {UrunDetayi},
        data() {
            return {
                path: urunService.imageUrlOrta,
                kategoriList: [],
                kategoriUrunList: [],
                urunData: [],
                odemeDurumu: 0,
                gelensiparisId: this.$store.state.guncellenecekMasaId,

                odemeSekli: "1",
                deneme: "",
                vergiMiktari: "",
                guncMsiparisId: "",
                arrayUrun: [],
                arrayKat: [],
                secilenUrunler: [],
                urunDetayVisible: false,
            }
        },
        watch: {
            // secilenUrunler(val) {
            //      console.log(val)
            // },
        },
        computed: {
            amount() {
                let sum = this.secilenUrunler.reduce(function (accumulator, currentValue) {
                    if (currentValue.malzemeList.length > 0) {
                        // sum1 = _.reduce(currentValue.malzemeList, (sum, obj) => sum + parseFloat(obj.fiyat), 0)
                        var sum1 = currentValue.malzemeList.reduce(function (sum, obj) {
                            var sum1 = 0
                            obj.fiyat = obj.fiyat === '' ? 0 : obj.fiyat;
                            if (obj.durum === 1) {

                                return sum + parseFloat(obj.fiyat)
                            }
                            return sum1
                        }, 0);

                        return accumulator + (currentValue.fiyat * currentValue.adet) + sum1
                    } else {
                        return accumulator + (currentValue.fiyat * currentValue.adet)

                    }
                }, 0);
                return sum;
            },
            amount_text() {
                return _.replace(this.amount.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1'))
            },
            amount_vergi() {
                // if (this.$store.getters.getTur === 0) {
                //     this.vergiMiktari = 7.7;
                //     return this.amount * (7.7 / 100)
                //
                // } else if (this.$store.getters.getTur === 1) {
                //     this.vergiMiktari = 2.5;
                //     return this.amount * (2.5 / 100)
                // }
                this.vergiMiktari = 7.7;
                return this.amount * (7.7 / 100)

            },
            amount_text_vergi() {
                return this.amount_vergi.toFixed(2)
            },
            amount_kasa() {
                return parseFloat(this.amount_text) - parseFloat(this.amount_text_vergi)
            },
            amount_text_kasa() {
                return _.replace(this.amount_kasa.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1.').replace(/\.(\d+)$/, ',$1'))
            },
            adet() {
                return _.reduce(this.secilenUrunler, (sum, obj) => sum + (obj.adet), 0)
            },
            adet_text() {
                return _.replace(this.adet)
            },
        },
        mounted() {


            this.getMasaSiparisId(this.gelensiparisId)

            if (localStorage.getItem("userDataKaderFoodKasa")) {
                var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
                this.getUrunKategori()
            } else {
                this.$store.commit('setLogout');
                this.$router.push("/login");
            }


        },

        methods: {
            getMasaSiparisId(test) {
                musteriService.siparisMasaDetay(test).then((response) => {
                    if (response.status === 200) {
                        this.guncMsiparisId = response.data.msiparisId
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            },
            fisCikar() {
                barkodCikti(this.$store.getters.getSevkiyat.adSoyad,this.$store.getters.getDil)
            },
            getUrunKategori() {
                urunService.ukategoriList(this.$store.getters.getDil).then((response) => {
                    if (response.status === 200) {
                        this.kategoriList = response.data;
                        this.kategoriSecim(this.kategoriList[0].ukategoriId);
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            },
            urunSecim(val) {
                this.urunData = val
                this.urunDetayVisible = true;
                this.deneme = val
            },
            sepeteAt(handle) {
                let self = this;
                var obj = this.secilenUrunler.find(data => data.urunId === handle.urunId && JSON.stringify(data.malzemeList.sort(this.compare)) === JSON.stringify(handle.malzemeList.sort(this.compare)));
                if (obj === undefined) {
                    this.arrayUrun.push(this.deneme);
                    this.secilenUrunler.push(handle)
                } else {
                    if (JSON.stringify(obj.malzemeList) === JSON.stringify(handle.malzemeList)) {
                        obj.adet = obj.adet + 1;
                    } else {
                        self.secilenUrunler.push(handle)
                    }
                }
            },
            compare(a, b) {
                if (a.umalzemeId < b.umalzemeId) {
                    return -1;
                }
                if (a.umalzemeId > b.umalzemeId) {
                    return 1;
                }
                return 0;
            },
            kategoriSecim(val) {
                this.arrayKat = [];
                var obj = this.arrayKat.find(data => data === val);
                if (obj === undefined) {
                    urunService.kategoriUrunList(val,this.$store.getters.getDil).then((response) => {
                        if (response.status === 200) {
                            this.arrayKat.push(val);
                            this.kategoriUrunList = response.data;
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                } else {
                    this.arrayKat.splice(this.arrayKat.indexOf(val), 1)
                }
            },
            removeUrun(scope, index) {
                this.secilenUrunler.splice(index, 1);
                this.arrayUrun.splice(index, 1)
            },
            odemeDurumuChange() {
                if (this.odemeDurumu === 1)
                    this.odemeDurumu = 0
                else if (this.odemeDurumu === 0)
                    this.odemeDurumu = 1
            },
            sipisTamamla() {
                musteriService.siparisGuncelle(this.guncMsiparisId,this.secilenUrunler).then((response) => {
                    if (response.status === 200) {
                        barkodCikti(this.$store.getters.getSevkiyat.adSoyad,this.$store.getters.getDil);
                        notification.Status('success', this, this.$t('bildirim.yeniUrunlerBasarili'));
                        this.$router.push({name: 'dashboard'})
                    } else {
                        notification.Status(response.status, this)
                    }
                })

            }
        },
    }


    function barkodCikti(gelenAdSoyad,gelenDil) {
        if (gelenAdSoyad) {
            var musteriAdi = gelenAdSoyad;
            if (gelenDil === 'tr') {
                var isimSoyisim = 'İsim Soyisim'
            } else {
                isimSoyisim = 'Vorname Familienname'
            }
        } else {
            musteriAdi = '';
            isimSoyisim = ''
        }
        const ciktiCSS = "div{border: none !important;padding:0 !important;margin: 0 !important; display: inline-block}" +
            "body {margin: 0 ; padding:0 0.5cm;width:7.2cm;font-family: monospace;}" +
            ".ozelPaddingv2 {display: flex;justify-content:space-between}" +
            ".el-col-16 {width: 66.66667%;}" +
            ".FisBosluk {display: block}" +
            ".ozelBorder {border-bottom: 1px solid #000 !important;margin-bottom: 5px !important; padding-bottom: 5px !important;}" +
            ".el-row {display: block; width:100%}" +
            ".ortala {text-align:center}" +
            ".el-col-2 {display: none}" +
            ".el-col-4 {width: 16.66667%;}" +
            ".el-col-14 {width: 66.66667%;}" +
            "br {display:none}" +
            ".icRowDiv {display: block}" +
            ".boldYazi {font-weight: bold}" +
            ".sag {text-align:right}" +
            ".ekYer {background:transparent !important;margin-bottom: 5px!important;display:block;padding-left: 10px !important;}" +
            ".ekYer div {display:block}" +
            ".icRowDiv .icRow{border-bottom: 1px solid #000 !important;margin-bottom: 5px !important; padding-bottom: 5px !important;}" +
            ".toplamBolgesi {font-weight: bold}" +
            "@page { margin: 0 9mm;}" +
            ".fiyatBilgisi {float:right;padding-right: 2px !important;}" +
            ".altCizgi {display: none}" +
            ".el-col-24 {width: 100%}" +
            ".muhittin {display: block; float: left;}";

        var openWindow = window.open("", "", "_blank,width=400,height=500");
        const tarih = moment().format('yyyy/MM/DD');
        const saat = moment().subtract(1, 'hours').format('HH:mm');
        // const logo = '<img class="image-logo" src="https://www.hakanbt.com.tr/images/logo.png" alt="logo" style="max-width: 100%; height: 50px"/>';
        // openWindow.document.write('<div class="ortala" style="display: block;filter: brightness(0);margin-bottom: 5px !important;">' + logo + '</div>');
        openWindow.document.write('<div class="sag" style="display: block;margin-bottom: 25px !important;"><div style="float: left">' + tarih + '</div><div style="float: right">' + saat + '</div></div>');
        openWindow.document.write('<span class="muhittin"> <strong>'+ isimSoyisim +':</strong> '+ musteriAdi +'</span> ');
        openWindow.document.write('<div class="FisBosluk">' + $(".fisAlani").html() + '</div>');
        openWindow.document.write('<style>' + ciktiCSS + '</style>');
        openWindow.focus();
        openWindow.print();
        setTimeout(function () {
            openWindow.close();
        }, 1000)
    }
</script>

<style lang="scss" scoped>

    .grid-content {
        border-radius: 4px;
        margin-top: 30px;
        padding: 1px;
        text-align: center;

    }

    .bg-purple {
        background: #d3dce6;
        font-weight: bold;
    }

    .kategori {
        position: relative;
        cursor: pointer;
        padding: 0;
        margin: 0 0 30px;

        .kategoriRenk {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            width: 100%;
            background: red;
            border-radius: 10px;
        }

        img {
            max-width: 100%;
            border-radius: 10px;
        }

        .kategoriDetay {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
            height: 40px;
            width: 90%;
            text-align: center;
            background: #e0e5eb;
            border-radius: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            div {
                font-size: 14px !important;
            }
        }
    }

    .urun {
        position: relative;
        padding-bottom: 50px;
        cursor: pointer;

        img {
            max-width: 100%;
            border-radius: 10px;
        }

        .urunDetay {
            position: absolute;
            left: 50%;
            bottom: 9px;
            transform: translateX(-50%);
            height: 45px;
            width: 80%;
            text-align: center;
            background: #e0e5eb;
            border-radius: 0 0 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .activeurun {
        .urunDetay {
            background: #4a596a;
            color: white;

        }
    }

    .activeKat {
        .kategoriDetay {
            background: #4a596a;
            color: white;

        }
    }

</style>

<style>
    .ozelPadding {
        padding: 0 15px;
        min-height: 44px;
        display: flex;
        align-items: center;
    }

    .ozelPaddingv2 {
        padding: 0 15px;
        min-height: 66px;
        display: flex;
        align-items: center;
        justify-content: space-between
    }

    .solBosluk {
        padding-left: calc(8.33333% + 15px)
    }

    .icRowDiv .icRow {
        border: 1px solid #4b5264;
        background: #ffffff;
    }

    .icRowDiv .icRow:last-of-type:not(:first-of-type) {
        border-bottom: 0
    }

    .icRowDiv .icRow:first-of-type {
        border-top: 0
    }

    .ozelBorder {
        border: 1px solid #4b5264;
        background: #f1f1f1;
    }

    .borderLeft {
        border-left: 1px solid #4b5264;
    }

    .borderRight {
        border-right: 1px solid #4b5264;
    }

    .ortala {
        justify-content: center;
    }

    .boldYazi {
        font-weight: bold;
    }

    .ekYer {
        border-top: 1px solid #4b5264;
        padding-left: calc(8.33333% + 30px);
        padding-right: 28px;
        min-height: 44px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .ekYer span {
        position: relative;
        width: 100%;
        display: block;
        line-height: 22px;
    }

    .fiyatBilgisi {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    .ekYer span:not(:first-of-type) {
        margin-top: 5px;
    }

    .ekYer span::before {
        content: "";
        position: absolute;
        left: -10px;
        height: 8px;
        width: 8px;
        background: #4b5264;
        top: 50%;
        transform: translateY(-50%);
        border-radius: 50%;
        box-shadow: 0 0 4px 1px rgba(79, 85, 104, .4);
    }

    .ekYer span::after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        border-bottom: 1px dotted #4b5264;
    }

    .altCizgi {
        position: relative;
    }

    .altCizgi::before {
        position: absolute;
        content: "";
        height: 1px;
        width: calc(100% - 30px);
        left: 15px;
        bottom: 0;
        background: #c9c9c9;
    }

    .ortaYazi {
        font-size: 18px !important;
    }

    .sonTutar {
        font-size: 22px !important;
        color: red;
        font-weight: bold;
    }

    .butonAyarlari {
        width: 100%;
        display: block;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 16px !important;
    }

    .odemeButon {
        background: #4b5264 !important;
        border: none !important;
        color: #ffffff !important;
        border-radius: 0;
        box-shadow: 0 0 2px 10px rgba(100, 100, 100, .2);
        margin-bottom: 10px;
        width: 100%;
        display: block;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 28px !important;
    }

    .odemeAlindi {
        background: #ffffff !important;
        color: #00933e !important;
        border: none !important;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 28px !important;
        box-shadow: 0 0 8px 2px rgba(150, 150, 150, .4);
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: bold;
    }

    .activeOdeme {

        background: #00933e !important;
        color: #ffffff !important;
        border: none !important;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 28px !important;
        box-shadow: 0 0 8px 2px rgba(150, 150, 150, .4);
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: bold;

    }

    .el-radio-button__inner {
        width: 100%;
    }

    .odemeRadio {
        width: 100%;
    }

    .odemeRadio .el-radio-button {
        width: calc(100% / 3)
    }

    .odemeRadio .el-radio-button__inner:hover {
        color: #00933e;
    }

    .odemeRadio .el-radio-button__orig-radio:checked + .el-radio-button__inner {
        background-color: #00933e;
        border-color: #00933e;
    }

    .odemeRadio .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
        color: #ffffff;
    }


    .fonts-16 {
        font-size: 16px !important;
    }
    .fonts-14 {
        font-size: 14px !important;
    }
</style>