<template>
    <div class="scrollable login-page align-vertical giris" style="margin: 0 !important;">
        <el-form status-icon :model="form" :rules="rulesForm" ref="form" class="align-vertical-middle" style="width: 100%">
            <div class="form-wrapper">
                <div class="form-box card-base card-shadow--extraLarge logoAlani">
                    <div class="flex text-center center">
                        <img class="ortaLogo" src="@/assets/images/logo3.png" alt="logo"/>
                    </div>
                    <div class="flex text-center center inputAlani">
                        <float-label class="styled">
                            <el-input size="mega" name="adSoyad" :placeholder="$t('sevkiyat.adSoyad')" v-model="form.adSoyad"></el-input>
                        </float-label>
                    </div>
                    <div class="flex text-center center inputAlani">
                        <float-label class="styled">
                            <el-input size="mega" name="telefon" v-mask="'+41(##) ### ## ##'" :placeholder="$t('sevkiyat.telefon')" v-model="form.telefon"></el-input>
                        </float-label>
                    </div>
                    <div class="flex text-center center inputAlani">
                        <float-label class="styled">
                            <el-input type="textarea" size="mega" name="adres" :placeholder="$t('sevkiyat.adres')" v-model="form.adres"></el-input>
                        </float-label>
                    </div>
                </div>
                <div class="form-box card-base card-shadow--extraLarge fatihCol">
                    <div class="flex text-center center">
                        <el-button size="small" @click="devamEt" class="ozelButon yeni">
                            {{$t("sevkiyat.devamEt")}}
                        </el-button>
                    </div>
                </div>
            </div>
        </el-form>
    </div>
</template>

<script>
    import musteriService from '../WSProvider/MusteriService';
    import moment from 'moment-timezone'
    import notification from "../notification";

    let base64 = require('base64_js');
    export default {
        name: 'Sevkiyat',
        data() {
            return {
                form: {
                    adres: '',
                    adSoyad: '',
                    telefon: '',
                },
                rulesForm: {
                    adres: [{
                        required: true,
                        message: " ",
                        trigger: 'change'
                    }],
                },
            }
        },
        mounted() {
            //console.log(moment().format('yyyy-MM-DD h:mm:ss'));
            if (localStorage.getItem("userDataKaderFoodKasa")) {
                var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
            } else {
                this.$store.commit('setLogout');
                this.$router.push("/login");
            }
        },
        methods: {
            devamEt() {
                this.$store.commit('changeSevkiyat', this.form);
                this.$router.push({name: 'siparis'})
            }
        }
    }
</script>

<style lang="scss">
    @import '../assets/scss/_variables';

    .giris {
        background-image: url("../assets/images/katman/giris-bg.jpg") !important;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% 100%;
        height: 100%;
        width: 100%;
        margin: 0 -30px;
    }

    .login-page {
        background: $text-color;
        margin-left: -30px;
        margin-right: -30px;

        .form-wrapper {
            width: 100%;
        }

        .form-box {
            width: 100%;
            max-width: 60% !important;
            padding: 30px;
            box-sizing: border-box;
            margin: 20px auto;

            a {
                font-size: 14px;
                color: $text-color-accent;
                text-decoration: none;
                font-weight: 500;
            }


            .image-logo {
                width: 150px !important;
                margin: 0px auto;
                margin-bottom: 30px;
                display: block;
            }

            .login-btn {
                width: 100% !important;
                height: 60px !important;
            }

            .login-btn,
            .social-btn {
                width: 160px;

                &.google {
                    margin-bottom: 10px;
                    background-color: #d73d32;
                    color: white;

                    &:hover {
                        border-color: #d73d32;
                    }
                }

                &.facebook {
                    background-color: #3f5c9a;
                    color: white;

                    &:hover {
                        border-color: #3f5c9a;
                    }
                }
            }

            .signin-box {
                font-size: 14px;
            }
        }
    }

    .kayit .el-radio {
        width: 50%;
        height: 55px;
        margin: 0;
    }

    .el-input--mega .el-textarea__inner {
        font-size: 18px;
        &::placeholder {
            font-size: 24px;
        }
    }

    .kayit .el-radio__label {
        font-size: 20px;
    }

    @media (max-width: 768px) {
        .layout-container .container .view.login-page {
            margin-left: -5px;
            margin-right: -5px;
            max-width: calc(100% + 10px);
        }
    }

    .vfl-has-label.styled {
        margin: 0 !important;
    }
</style>
