<template>
    <div>
        <el-row>
            <el-col :span="20" :offset="2">
                <div class="grid-content bg-purple">
                    <p>{{$t("masa.baslik")}}</p>
                </div>
                <div>
                    <el-row :gutter="20">
                        <el-col :span="12" v-for="(item,index) in kategoriList" :key="item.mkategoriId">
                            <div class="masaKatBtn">
                                <el-button :class="index === 0 ? 'aktifButon' : ''" @click="deneme(index); masaKategoriChange(item)" :data-deneme="index === 0 ? 'bahce' : 'restorantic'">
                                    {{baslik =  item.baslik }}
                                </el-button>
                            </div>
                        </el-col>
                    </el-row>
                </div>
                <div>
                    <el-row :gutter="20">
                        <el-col v-for="i in masaList" :key="i.masaId" :span="4">
                            <div :class="{ active: masaKontrol(i) , active: i.dolu > 0}">
                                <el-card shadow="always" @click.native="masaStart(i)">
                                    <div class="masaNo">{{i.masaNo}}</div>
                                    <div class="masa"></div>
                                </el-card>
                                <div v-if="masaKontrol(i)" class="masaSaat">
                                    <i class="mdi mdi-clock-time-twelve-outline"></i> {{(array.find(data => data.masaNo === i)).time}}
                                </div>
                                <div class="text-center">
                                    <button class="siparisEkleButon" v-on:click="siparisGuncelle(i.masaId)" v-if="i.dolu !== '0'">
                                        {{$t("masa.yeniUrun")}}
                                    </button>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>
        <el-dialog
                :visible.sync="onayDialogVisible"
                width="40%"
                center>
            <span slot="default">
                    <span style="font-size: 18px;margin: 0;word-break: break-word;text-align: center;display: block">
                        <strong>{{$t("masa.masatasima")}}</strong>
                    </span>
                        <el-row class="margin-bottom-30">
                            	<el-col :lg="19" :md="19" :sm="24" :xs="24">
                            	    <el-select v-model="tasinacakMasa" style="width: 100%">
                            		    <el-option v-for="item in bosMasalar" :value="item.masaId" :key="item.masaId" :label="item.masaNo"></el-option>
                            	    </el-select>
                            	</el-col>
                            <el-col :lg="5" :md="5" :sm="24" :xs="24" class="text-right">
                                <button class="masaTasimaButon" @click="masaTasi">{{$t('masa.onayla')}}</button>
                            </el-col>
                        </el-row>
                    <div class="card-shadow--medium fisAlani">
                        <el-row class="ozelBorder">
                            <el-col :span="16" class="ozelPadding borderRight solBosluk boldYazi">{{$t("siparis.urunAdi")}}</el-col>
                            <el-col :span="4" class="ozelPadding borderRight ortala boldYazi">{{$t("siparis.miktar")}}</el-col>
                            <el-col :span="4" class="ozelPadding ortala boldYazi sag">{{$t("siparis.tutar")}}</el-col>
                        </el-row>
                        <div v-for="(item,index) in masaHesapDetay.urun" :key="index" class="icRowDiv">
                            <el-row class="icRow">
                                <el-col :offset="2" :span="14" class="ozelPadding borderRight">{{item.urunAdi }}</el-col>
<!--                                <el-col :offset="2" :span="14" class="ozelPadding borderRight">{{item.urunAdi}}</el-col>-->
                                <el-col :span="4" class="ozelPadding borderRight ortala">{{item.adet}}</el-col>
                                <el-col :span="4" class="ozelPadding ortala sag">{{item.toplamFiyat}}</el-col>
                                <el-col :span="24" v-if="item.malzeme.length>0">
                                    <div class="boldYazi ekYer" style="background: #f1f1f1">
                                        <div v-for="i in item.malzeme">
                                            <span v-if="i.durum === '0' ">
<!--                                                {{i.malzemeAdi}}-->
                                                {{i.malzemeAdi}}
                                                <div class="fiyatBilgisi">
                                                   <i style="color: red" class="mdi mdi-close"></i>
                                                </div>
                                            </span>
                                            <span v-else>
                                                {{i.malzemeAdi}}
                                                <div class="fiyatBilgisi">
                                                   {{i.fiyat !== '0' ? i.fiyat : ' - '}}
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <el-col :span="24" class="ozelBorder" style="background-color: #fff">
                            <div class="ozelPadding altCizgi" style="justify-content: space-between">
                                <div> {{$t("siparis.siparisOzeti")}}:</div>
                                <div>
                                    <span>{{masaHesapUrunSayisi}} &nbsp;</span>
                                    <span>{{$t("siparis.urun")}}</span>
                                </div>
                            </div>

                            <div class="ozelPaddingv2">
                                <div class="ortaYazi">{{$t("siparis.tutar")}}</div>
                                <div class="ortaYazi boldYazi">{{masaHesapDetay.toplamFiyat}}</div>
                            </div>
                        </el-col>

                        <el-col :span="24" style="border: 1px solid #4b5264;border-top: 0; background-color: #ffffff" class="toplamBolgesi">
                            <div class="ozelPaddingv2">
                                <span class="ortaYazi">{{$t("siparis.toplam")}} <br> {{$t("siparis.tutar")}}</span>
                                <span class="sonTutar">{{masaHesapDetay.toplamFiyat}}</span>
                            </div>
                        </el-col>
                    </div>

                    <el-col :lg="24" :md="24" :sm="24" :xs="24" style="padding: 0; margin: 15px 0">
                        <el-radio-group class="odemeRadio" v-model="odemeSekli">
                            <el-radio-button label="1">{{$t("siparis.nakit")}}</el-radio-button>
                            <el-radio-button label="2">{{$t("siparis.kart")}}</el-radio-button>
                            <el-radio-button label="3">{{$t("siparis.ucretsiz")}}</el-radio-button>
                        </el-radio-group>
                    </el-col>
                    <el-col :span="24" style="padding: 0 !important;margin-top: 15px">
                        <el-button :class="{ activeOdeme: odemeDurumu === 1 }" class="card-shadow--medium butonAyarlari odemeAlindi" size="mini" type="success" @click="odemeDurumuChange">
                            <i class="mdi mdi-check-circle-outline"></i> {{$t("siparis.odemeAlindi")}}
                        </el-button>
                    </el-col>

                    <el-col :span="24" style="padding: 0 !important;margin-top: 15px">
                        <el-button v-if="odemeDurumu === 1 || odemeSekli === '3'" size="mini" @click="odemeYap" class="odemeButon">
                            {{$t("masa.odemeTamamla")}}
                        </el-button>
                    </el-col>
            </span>
            <span slot="footer" class="dialog-footer">
                    <el-button style="visibility: hidden" type="danger" size="mini" icon="el-icon-close"
                               @click="onayDialogVisible = false"></el-button>
                </span>
        </el-dialog>
    </div>
</template>

<script>
    import EventBus from '@/components/event-bus'
    import masaService from "../WSProvider/MasaService";
    import musteriService from "../WSProvider/MusteriService";
    import notification from "../notification";

    import moment from 'moment-timezone'

    import JQuery from 'jquery'

    let $ = JQuery;
    let base64 = require('base64_js');

    export default {
        name: "Masa",

        data() {
            return {
                kategoriList : [],
                masaList : [],
                masaHesapDetay : [],
                masaHesapUrunSayisi : '',
                bosMasalar : [],
                tasinacakMasa : '',
                odemeSekli : '1',
                odemeDurumu : 0,


                array: [],
                isActive: true,
                onayDialogVisible: false,
                kapatilacakMasaId: '',
                kapatilacakMsiparisId: '',
                testMasaId : ''
            }
        },

        mounted() {
            // let self = this;
            // EventBus.$on('musteriSiparisList', function (bool) {
            //     if (bool) {
            //         self.getMusteriSatisListesi();
            //     }
            // });
            // this.getMusteriSatisListesi()

            if (localStorage.getItem("userDataKaderFoodKasa")) {
                var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
                this.getKategoriList();
            } else {
                this.$store.commit('setLogout');
                this.$router.push("/login");
            }


        },

        methods: {
            siparisGuncelle(val){

                this.$store.commit('changeMsiparisId', val);
                this.$router.push({name: 'siparisguncelle'})
            },
            deneme (attrKontrol) {
                if (attrKontrol === 0) {
                    attrKontrol = 'bahce'
                } else {
                    attrKontrol = 'restorantic'
                }
                $('body').find('.aktifButon').removeClass('aktifButon')
               var buton = $('body').find("[data-deneme='" + attrKontrol + "']")
                $(buton).addClass('aktifButon')
            },
            masaTasi(){
                musteriService.masaAktarma(this.tasinacakMasa,this.kapatilacakMsiparisId).then((response) => {
                    if(response.status === 200) {
                        this.onayDialogVisible=false;
                        notification.Status('success', this, 'Masa taşındı!');
                        this.$router.push({name: 'dashboard'})
                    }else  notification.Status(response.status, this)
                })
            },
            masaKategoriChange(val) {
                this.kategoriSecim(val.mkategoriId);
            },
            getKategoriList(val) {
                masaService.kategoriList().then((response) => {
                    if(response.status === 200) {
                        this.kategoriList = response.data;
                        this.kategoriSecim(this.kategoriList[0].mkategoriId);
                    }else  notification.Status(response.status, this)
                })

            },
            kategoriSecim(val) {
                masaService.kategoriMasaList(val).then((response) => {
                    if (response.status === 200) {
                        this.masaList = response.data;
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            },
            odemeDurumuChange() {
                if (this.odemeDurumu === 1)
                    this.odemeDurumu = 0
                else if (this.odemeDurumu === 0)
                    this.odemeDurumu = 1
            },
            masaKontrol(val) {
                var obj = this.array.find(data => data.masaNo === val);
                if (obj === undefined)
                    return false
                else
                    return true
            },
            masaStart(val) {
                if (val.dolu === '0') {
                    musteriService.siparisTanimlama(this.$store.getters.getTur,this.$store.getters.getSevkiyat.adSoyad,this.$store.getters.getSevkiyat.telefon,this.$store.getters.getSevkiyat.adres,this.$store.getters.getOdemeDurum,this.$store.getters.getOdemeSekli,val.masaId,this.$store.getters.getUrunList).then((response) => {
                        if(response.status === 200){
                            var obj = this.array.find(data => data.masaNo === val);
                            if (obj === undefined) {
                                this.array.push({
                                    'masaNo' : val,
                                    'time' : moment().format('LT')
                                })
                            } else {
                                this.array.splice(this.array.indexOf(obj), 1)
                            }
                            // notification.Status('success', this, 'Sipariş Alındı.');
                        }else notification.Status(response.status, this)
                    });

                    masaService.masaDoluGuncelle(val.masaId,'1').then((response) => {
                        if (response.status === 200) {
                            notification.Status('success', this, this.$t('bildirim.siparisBasarili'));
                            this.$router.push({name: 'dashboard'})
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                } else {

                    this.onayDialogVisible = true;
                    this.kapatilacakMasaId = val.masaId;

                    this.getBosMasalar();

                    musteriService.siparisMasaDetay(val.masaId).then((response) => {
                        if (response.status === 200) {
                            this.masaHesapDetay = response.data;
                            this.masaHesapUrunSayisi = response.data.urun.length;
                            this.kapatilacakMsiparisId = response.data.msiparisId
                        } else {
                            notification.Status(response.status, this)
                        }
                    })
                }
            },
            getBosMasalar() {
                let self = this;
                self.bosMasalar = [];
                masaService.masaList("1").then((response) => {
                    if (response.status === 200) {
                        response.data.forEach(function (e) {
                            if (e.dolu === "0") {
                                self.bosMasalar.push(e)
                            }
                        });
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            },

            odemeYap(){
                musteriService.siparisOdemeGuncelle(this.kapatilacakMsiparisId,this.odemeDurumu,this.odemeSekli).then((response) => {
                    if (response.status === 200) {
                        this.masaHesapKapat()
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            },

            masaHesapKapat() {
                masaService.masaDoluGuncelle(this.kapatilacakMasaId,'0').then((response) => {
                    if (response.status === 200) {
                        notification.Status('success', this, this.$t('bildirim.islemBasarili'));
                        this.$router.push({name: 'dashboard'})
                    } else {
                        notification.Status(response.status, this)
                    }
                })
            }
        },
    }

</script>

<style lang="scss" scoped>

    .activeOdeme {

        background: #00933e !important;
        color: #ffffff !important;
        border: none !important;
        padding-top: 15px;
        padding-bottom: 15px;
        font-size: 28px !important;
        box-shadow: 0 0 8px 2px rgba(150, 150, 150, .4);
        text-transform: uppercase;
        letter-spacing: 3px;
        font-weight: bold;

    }

    .grid-content {
        border-radius: 4px;
        margin-top: 30px;
        padding: 1px;
        text-align: center;

    }

    .bg-purple {
        background: #d3dce6;
        font-weight: bold;
    }


    .masaKatBtn {

        margin-top: 20px;

        .el-button {
            width: 100%;
            font-weight: bold;
            background: #e0e5eb;
            padding-bottom: 15px;
            padding-top: 15px;
            font-size: 20px !important;
            box-shadow: 0 3px 15px 2px rgba(0,0,0,.3);
            letter-spacing: 2px;
            transition: 300ms all ease-in-out;
            border: none;
        }
        .el-button.aktifButon,
        .el-button:active,
        .el-button:hover {
            background: #4a596a;
            border: none;
            color: white;
        }

    }

    .active {
        position: relative;

        .el-card {
            background: #bc1722 !important;

        }

        .masaNo {
            color: #bc1722;
            font-weight: bold;
            font-size: 16px !important;
            background: white;
            text-align: center;
            height: 15px;
            width: 60px;
            padding: 10px 0;
            border-radius: 0 0 10px 10px;
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .masa {
            background: url("../assets/images/katman/masa2.png") center center no-repeat;
            height: 180px;
        }


        .masaSaat {
            position: absolute;
            left: 50%;
            bottom: -30px;
            transform: translateX(-50%);
            height: 30px;
            width: 100px;
            text-align: center;
            background: #e0e5eb;
            border-radius: 0 0 10px 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-weight: bold;
        }
    }


    .el-card {
        cursor: pointer;
        margin-top: 50px;
        background: white;
        height: 180px;
        position: relative;
    }

    .masa {
        background: url("../assets/images/katman/masa1.png") center center no-repeat;
        height: 180px;
    }

    .masaNo {
        color: white;
        font-weight: bold;
        font-size: 16px !important;
        background: #4a596a;
        text-align: center;
        height: 15px;
        width: 60px;
        padding: 10px 0;
        border-radius: 0 0 10px 10px;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .masaTasimaButon {
        border: none;
        background: #4b5264;
        color: #ffffff;
        font-weight: bold;
        height: 40px;
        line-height: 40px;
        text-align: center;
        padding: 0 20px;
        cursor: pointer;
    }

    .siparisEkleButon {
        background: #4a596a;
        padding: 10px 30px;
        color: #ffffff;
        border-radius: 0 0 8px 8px;
        outline: none;
        border: none;
        cursor: pointer;
        font-weight: bold;
    }

</style>
