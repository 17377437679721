import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');
var userDataKaderFoodKasa = localStorage.getItem("userDataKaderFoodKasa") ? JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa")))) : "";

var serviceName = "index";
var masaService = {
    imageUrlBuyuk: WsProvider.resim + "/" + userDataKaderFoodKasa["firmaKodu"] + "/buyuk/",
    imageUrlOrta: WsProvider.resim + "/" + userDataKaderFoodKasa["firmaKodu"] + "/orta/",
    imageUrlKucuk: WsProvider.resim + "/" + userDataKaderFoodKasa["firmaKodu"] + "/kucuk/",
    imageUrlEnKucuk: WsProvider.resim + "/" + userDataKaderFoodKasa["firmaKodu"] + "/enkucuk/",
    path: "https://www.kaderfood.org.tr/",
    kategoriDetay(mkategoriId, dil) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                mkategoriId: mkategoriId,
                dil: dil,
                serviceName: "masa",
                methodName: "kategoriDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },


    kategoriList() {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                durum: 1,
                serviceName: "masa",
                methodName: "kategoriList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    kategoriCount(durum) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                durum: durum,
                serviceName: "masa",
                methodName: "kategoriCount"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    masaDetay(masaId) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                masaId: masaId,
                serviceName: "masa",
                methodName: "masaDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    masaCount(durum) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                durum: durum,
                serviceName: "masa",
                methodName: "masaCount"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    masaList(durum) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                durum: durum,
                serviceName: "masa",
                methodName: "masaList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    kategoriMasaList(mkategoriId) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                mkategoriId: mkategoriId,
                serviceName: "masa",
                methodName: "kategoriMasaList"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    masaDoluGuncelle(masaId,dolu) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                masaId: masaId,
                dolu: dolu,
                serviceName: "masa",
                methodName: "masaDoluGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

}

export default masaService

