<template>
	<vue-scroll class="login-page align-vertical">
		<div class="form-wrapper align-vertical-middle">
			<div class="form-box card-base card-shadow--extraLarge" style="max-width: 30% !important;">
				<img class="image-logo" src="@/assets/images/logo3.png" alt="logo"/>

				<float-label class="styled" style="margin-top: 35px !important;">
					<input type="username" name="username" v-model="username" placeholder="Kullanıcı Adı">
				</float-label>
				<float-label class="styled" style="margin-top: 35px !important;">
					<input type="password" name="password" v-model="password" placeholder="Şifre">
				</float-label>

				<div class="flex text-center center pt-30 pb-10">
					<el-button plain size="small" @click="login" class="login-btn tada animated">
						GİRİŞ
					</el-button>
				</div>

			</div>
		</div>
	</vue-scroll>
</template>

<script>
	import yonetimService from '../../../WSProvider/YoneticiService';
	import notification from '../../../notification'

	let base64 = require('base64_js');
	export default {
		name: 'Login',
		data() {
			return {
				username: '',
				password: '',
			}
		},
		methods: {
			login() {
				let self = this;
				yonetimService.girisYap(this.username, this.password).then((response) => {
					if(response.status === 200){
						if (response.data.tur === '3') {
							var userDataKaderFoodKasa = base64.encode(encodeURI(JSON.stringify(response.data)));
							localStorage.setItem("userDataKaderFoodKasa", userDataKaderFoodKasa);
							self.$store.commit('setLogin');
							self.$router.push('/');
						}else notification.Status('warning',this,'Kasiyer Bulunamadı.')
					}else{
						notification.Status(response.status,this)
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../assets/scss/_variables';
	.vfl-has-label.styled {
		width: 100%;
		margin-top: 35px !important;
		padding-bottom: 0;
	}
	.login-page {
		background: $text-color;
		margin-left: -30px;
		margin-right: -30px;

		.form-wrapper {
			width: 100%;
		}

		.form-box {
			width: 100%;
			max-width: 340px;
			padding: 30px;
			box-sizing: border-box;
			margin: 20px auto;

			a {
				font-size: 14px;
				color: $text-color-accent;
				text-decoration: none;
				font-weight: 500;
			}

			.image-logo {
				width: 150px !important;
				margin: 0px auto;
				margin-bottom: 30px;
				display: block;
			}

			.login-btn ,
			.social-btn {
				width: 160px;

				&.google {
					margin-bottom: 10px;
					background-color: #d73d32;
					color: white;

					&:hover {
						border-color: #d73d32;
					}
				}
				&.facebook {
					background-color: #3f5c9a;
					color: white;

					&:hover {
						border-color: #3f5c9a;
					}
				}
			}

			.signin-box {
				font-size: 14px;
			}
		}
	}

	@media (max-width: 768px) {
		.layout-container .container .view.login-page {
			margin-left: -5px;
			margin-right: -5px;
			max-width: calc(100% + 10px);
		}
	}
</style>
