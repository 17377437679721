<template>
    <div class="giris scrollable only-y">
        <el-row>
            <el-col :span="20" :offset="2">
                <div class="girisCard">
                    <el-row>
                        <el-col :span="18" :offset="3">

                            <el-row :gutter="20" class="animated fadeInUp margin-bottom-20">
                                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                                    <div class="card-shadow--medium p-20 card-base">
                                        <div style="display: block; text-align: center">
                                            <h2 style="margin: 5px 0 10px">{{$t("rapor.restoranicNakit")}}</h2>
                                        </div>
                                        <hr style="margin: 0">
                                        <div style="display: block">
                                            <h3 style="margin: 5px 0">{{$t("rapor.gelir")}}</h3>
                                        </div>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.dun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi1.dun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.bugun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi1.bugun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.yediGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi1.yediGun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.otuzGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi1.otuzGun}}
                                            </el-col>
                                        </el-row>

                                        <hr style="margin-bottom: 0">
                                        <div style="display: block">
                                            <h3 style="margin: 5px 0">{{$t("rapor.adet")}}</h3>
                                        </div>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.dun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi1.dun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.bugun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi1.bugun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.yediGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi1.yediGun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.otuzGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi1.otuzGun}}
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-col>
                                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                                    <div class="card-shadow--medium p-20 card-base">
                                        <div style="display: block; text-align: center">
                                            <h2 style="margin: 5px 0 10px">{{$t("rapor.restoranicKart")}}</h2>
                                        </div>
                                        <hr style="margin: 0">
                                        <div style="display: block">
                                            <h3 style="margin: 5px 0">{{$t("rapor.gelir")}}</h3>
                                        </div>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.dun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi2.dun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.bugun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi2.bugun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.yediGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi2.yediGun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.otuzGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi2.otuzGun}}
                                            </el-col>
                                        </el-row>

                                        <hr style="margin-bottom: 0">
                                        <div style="display: block">
                                            <h3 style="margin: 5px 0">{{$t("rapor.adet")}}</h3>
                                        </div>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.dun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi2.dun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.bugun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi2.bugun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.yediGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi2.yediGun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.otuzGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi2.otuzGun}}
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-col>
                                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                                    <div class="card-shadow--medium p-20 card-base">
                                        <div style="display: block; text-align: center">
                                            <h2 style="margin: 5px 0 10px">{{$t("rapor.restorandisNakit")}}</h2>
                                        </div>
                                        <hr style="margin: 0">
                                        <div style="display: block">
                                            <h3 style="margin: 5px 0">{{$t("rapor.gelir")}}</h3>
                                        </div>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.dun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi3.dun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.bugun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi3.bugun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.yediGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi3.yediGun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.otuzGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi3.otuzGun}}
                                            </el-col>
                                        </el-row>

                                        <hr style="margin-bottom: 0">
                                        <div style="display: block">
                                            <h3 style="margin: 5px 0">{{$t("rapor.adet")}}</h3>
                                        </div>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.dun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi3.dun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.bugun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi3.bugun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.yediGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi3.yediGun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.otuzGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi3.otuzGun}}
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-col>
                                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                                    <div class="card-shadow--medium p-20 card-base">
                                        <div style="display: block; text-align: center">
                                            <h2 style="margin: 5px 0 10px">{{$t("rapor.restorandisKart")}}</h2>
                                        </div>
                                        <hr style="margin: 0">
                                        <div style="display: block">
                                            <h3 style="margin: 5px 0">{{$t("rapor.gelir")}}</h3>
                                        </div>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.dun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi4.dun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.bugun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi4.bugun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.yediGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi4.yediGun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.otuzGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{paraDizi4.otuzGun}}
                                            </el-col>
                                        </el-row>

                                        <hr style="margin-bottom: 0">
                                        <div style="display: block">
                                            <h3 style="margin: 5px 0">{{$t("rapor.adet")}}</h3>
                                        </div>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.dun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi4.dun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.bugun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi4.bugun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.yediGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi4.yediGun}}
                                            </el-col>
                                        </el-row>
                                        <el-row>
                                            <el-col :lg="18" :md="18" :sm="24" :xs="24">
                                                {{$t('rapor.otuzGun')}}
                                            </el-col>
                                            <el-col :lg="6" :md="6" :sm="24" :xs="24" class="text-right">
                                                {{countDizi4.otuzGun}}
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20" class="margin-top-40 animated fadeInUp">
                                <el-col :span="6" class="secimYap">
                                    <el-radio v-model="raporTuru" label="0" class="card-shadow--small" border>{{$t("rapor.dunRapor")}}</el-radio>
                                </el-col>
                                <el-col :span="6" class="secimYap">
                                    <el-radio v-model="raporTuru" label="1" class="card-shadow--small" border>{{$t("rapor.gunluk")}}</el-radio>
                                </el-col>
                                <el-col :span="6" class="secimYap">
                                    <el-radio v-model="raporTuru" label="2" class="card-shadow--small" border>{{$t("rapor.aylik")}}</el-radio>
                                </el-col>
                                <el-col :span="6" class="secimYap">
                                    <el-radio v-model="raporTuru" label="3" class="card-shadow--small" border>{{$t("rapor.yillik")}}</el-radio>
                                </el-col>
                            </el-row>


                            <el-row :gutter="20" class="margin-top-40 animated fadeInUp">
                                <el-col :span="12" :offset="6" class="secimYap">
                                    <button class="raporButon ozelButon yeni card-shadow--small" @click="getRapor">
                                        {{$t("rapor.goruntule")}}
                                    </button>
                                </el-col>
                            </el-row>

                        </el-col>
                    </el-row>
                </div>
            </el-col>
        </el-row>

        <el-dialog
                :title="$t('rapor.detay')"
                :visible.sync="dialogVisibleRapor"
                width="40%">
            <span class="yazdirIcerik">
                <el-row class="altCizgiYazdir">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" style="text-align: center;margin-bottom: 8px">
                        <b>{{this.getRaporName()}}</b>
                    </el-col>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" style="text-align: center;margin-bottom: 8px">
                        <b>{{$t('rapor.zrapor')}} - {{this.raporSiraNo}}</b><hr>
                    </el-col>
                    <el-col :lg="24" :md="24" :sm="24" :xs="24" style="text-align: right;margin-bottom: 8px">
                        <b>{{this.tarih}} <br>{{this.saat}}</b><hr>
                    </el-col>

                    <el-col :lg="15" :md="15" :sm="24" :xs="24" class="raporUrunAdi">
                        <div class="raporBasliklar">
                           {{$t('rapor.urunAdi')}}
                        </div>
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet">
                        <div class="raporBasliklar">
                            {{$t('rapor.urunAdet')}}
                        </div>
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunFiyat">
                        <div class="raporBasliklar">
                            {{$t('rapor.urunFiyati')}}
                        </div>
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet" style="text-align: right">
                        <div class="raporBasliklar">
                            {{$t('rapor.raporToplam')}}
                        </div>
                    </el-col>
                </el-row>
                <el-row v-for="item in restoranDisiKart">
                    <el-col :lg="15" :md="15" :sm="24" :xs="24">
                        {{item.urunAdi}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24">
                        {{item.toplamAdet}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet">
                        {{item.birimFiyat}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet" style="text-align: right">
                        {{item.toplamFiyat}}
                    </el-col>
                </el-row>
                <el-row v-for="item in restoranDisiNakit">
                    <el-col :lg="15" :md="15" :sm="24" :xs="24">
                        {{item.urunAdi}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24">
                        {{item.toplamAdet}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet">
                        {{item.birimFiyat}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet" style="text-align: right">
                        {{item.toplamFiyat}}
                    </el-col>
                </el-row>
                <el-row v-for="item in restoranIciKart">
                    <el-col :lg="15" :md="15" :sm="24" :xs="24">
                        {{item.urunAdi}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24">
                        {{item.toplamAdet}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet">
                        {{item.birimFiyat}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet" style="text-align: right">
                        {{item.toplamFiyat}}
                    </el-col>
                </el-row>
                <el-row v-for="item in restoranIciNakit">
                    <el-col :lg="15" :md="15" :sm="24" :xs="24">
                        {{item.urunAdi}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24">
                        {{item.toplamAdet}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet">
                        {{item.birimFiyat}}
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" class="raporUrunAdet" style="text-align: right">
                        {{item.toplamFiyat}}
                    </el-col>
                </el-row>

                <el-row class="ustCizgiCikti">
                    <el-col :lg="21" :md="21" :sm="24" :xs="24" style="font-weight: bold">
                        {{$t('siparis.kart')}} :
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" style="font-weight: bold;text-align: right">
                        {{(parseFloat(toplamFiyatBilgisiRestoranDisiKart) + parseFloat(toplamFiyatBilgisiRestoranIciKart)).toFixed(2)}}
                    </el-col>
                </el-row>
                <el-row class="ustCizgiCikti">
                    <el-col :lg="21" :md="21" :sm="24" :xs="24" style="font-weight: bold">
                        {{$t('siparis.nakit')}} :
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" style="font-weight: bold;text-align: right">
                        {{(parseFloat(toplamFiyatBilgisiRestoranDisiNakit) + parseFloat(toplamFiyatBilgisiRestoranIciNakit)).toFixed(2)}}
                    </el-col>
                </el-row>
                <el-row class="ustCizgiCikti">
                    <el-col :lg="21" :md="21" :sm="24" :xs="24" style="font-weight: bold">
                        {{$t("siparis.toplam")}}:
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" style="font-weight: bold;text-align: right">
                        {{toplamFiyatSon.toFixed(2)}}
                    </el-col>
                </el-row>
                <el-row class="ustCizgiCikti">
                    <el-col :lg="21" :md="21" :sm="24" :xs="24" style="font-weight: bold">
                        {{$t('rapor.restorandis')}} (2.5%) :
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" style="font-weight: bold;text-align: right">
                        {{(parseFloat(toplamVergiBilgisiRestoranDisiNakit) + parseFloat(toplamVergiBilgisiRestoranDisiKart)).toFixed(2)}}
                    </el-col>
                </el-row>
                 <el-row class="ustCizgiCikti">
                    <el-col :lg="21" :md="21" :sm="24" :xs="24" style="font-weight: bold">
                        {{$t('rapor.restoranic')}} (7.7%) :
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" style="font-weight: bold;text-align: right">
                        {{(parseFloat(toplamVergiBilgisiRestoranIciNakit) + parseFloat(toplamVergiBilgisiRestoranIciKart)).toFixed(2)}}
                    </el-col>
                </el-row>
                <el-row class="ustCizgiCikti">
                    <el-col :lg="21" :md="21" :sm="24" :xs="24" style="font-weight: bold">
                        {{$t("siparis.toplamVergi")}}:
                    </el-col>
                    <el-col :lg="3" :md="3" :sm="24" :xs="24" style="font-weight: bold;text-align: right">
                        {{toplamVergiSon.toFixed(2)}}
                    </el-col>
                </el-row>

            </span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisibleRapor = false">{{$t('rapor.raporKapat')}}</el-button>
                <el-button type="primary" @click="raporCiktiAl">{{$t('rapor.raporYazdir')}}</el-button>
            </span>
        </el-dialog>
        <div class="sqlKonum">
            <button class="ozelButon yeni card-shadow--medium" @click="sqlYedek">
                {{$t("rapor.sqlYedekAl")}}
            </button>
        </div>
    </div>
</template>

<script>
    import notification from "../notification";
    import musteriService from "../WSProvider/MusteriService";
    import JQuery from 'jquery'
    import moment from "moment-timezone";
    import controllerService from "../WSProvider/ControllerService";

    let $ = JQuery;

    var base64 = require('base64_js');
    export default {
        name: 'Raporlar',
        data() {
            return {
                tarih: moment().format('yyyy/MM/DD'),
                saat: moment().subtract(1, 'hours').format('HH:mm'),
                raporSiraNo: '',
                raporTuru: '',

                toplamVergiSon: 0,
                toplamVergiIc: 0,
                toplamVergiDis: 0,

                toplamFiyatSon: 0,
                toplamFiyatIc: 0,
                toplamFiyatDis: 0,

                toplamVergiBilgisiRestoranDisiKart: 0,
                toplamVergiBilgisiRestoranDisiNakit: 0,
                toplamVergiBilgisiRestoranIciKart: 0,
                toplamVergiBilgisiRestoranIciNakit: 0,


                toplamFiyatBilgisiRestoranDisiKart: 0,
                toplamFiyatBilgisiRestoranDisiNakit: 0,
                toplamFiyatBilgisiRestoranIciKart: 0,
                toplamFiyatBilgisiRestoranIciNakit: 0,


                restoranDisiKart: [],
                restoranDisiNakit: [],
                restoranIciKart: [],
                restoranIciNakit: [],


                karsilastirmaVeriRestoranDisiKart: [],
                karsilastirmaVeriRestoranDisiNakit: [],
                karsilastirmaVeriRestoranIciKart: [],
                karsilastirmaVeriRestoranIciNakit: [],


                dialogVisibleRapor: false,

                paraDizi1: [],
                paraDizi2: [],
                paraDizi3: [],
                paraDizi4: [],

                countDizi1: [],
                countDizi2: [],
                countDizi3: [],
                countDizi4: [],
            }
        },
        mounted() {
            this.getSiparisCount();
            this.getSiparisPara();
            if (localStorage.getItem("userDataKaderFoodKasa")) {
                var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
            } else {
                this.$store.commit('setLogout');
                this.$router.push("/login");
            }
        },
        methods: {
            sqlYedek() {
                window.open('http://89.252.181.6/kaderfood/kaderfood-api/dbbackupsql.php', "_blank")
            },
            raporCiktiAl() {
                // this.dialogVisibleRapor = false
                raporCikti(this.raporTuru)
            },
            getSiparisCount() {
                musteriService.siparisCountDashboardKasa("0", "1").then((response) => {
                    if (response.status === 200) {
                        this.countDizi1 = response
                    } else {
                        notification.Status(response.status, this)
                    }
                });
                musteriService.siparisCountDashboardKasa("0", "2").then((response) => {
                    if (response.status === 200) {
                        this.countDizi2 = response
                    } else {
                        notification.Status(response.status, this)
                    }
                });
                musteriService.siparisCountDashboardKasa("1", "1").then((response) => {
                    if (response.status === 200) {
                        this.countDizi3 = response
                    } else {
                        notification.Status(response.status, this)
                    }
                });
                musteriService.siparisCountDashboardKasa("1", "2").then((response) => {
                    if (response.status === 200) {
                        this.countDizi4 = response
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },
            getSiparisPara() {
                musteriService.siparisToplamDashboardKasa("0", "1").then((response) => {
                    if (response.status === 200) {
                        this.paraDizi1 = response
                    } else {
                        notification.Status(response.status, this)
                    }
                });
                musteriService.siparisToplamDashboardKasa("0", "2").then((response) => {
                    if (response.status === 200) {
                        this.paraDizi2 = response
                    } else {
                        notification.Status(response.status, this)
                    }
                });
                musteriService.siparisToplamDashboardKasa("1", "1").then((response) => {
                    if (response.status === 200) {
                        this.paraDizi3 = response
                    } else {
                        notification.Status(response.status, this)
                    }
                });
                musteriService.siparisToplamDashboardKasa("1", "2").then((response) => {
                    if (response.status === 200) {
                        this.paraDizi4 = response
                    } else {
                        notification.Status(response.status, this)
                    }
                });
            },

            raporDetayGor(gelenVeri) {
                let self = this;
                this.karsilastirmaVeriRestoranDisiKart = gelenVeri.restoranDisiKart;
                this.karsilastirmaVeriRestoranDisiNakit = gelenVeri.restoranDisiNakit;
                this.karsilastirmaVeriRestoranIciKart = gelenVeri.restoranIciKart;
                this.karsilastirmaVeriRestoranIciNakit = gelenVeri.restoranIciNakit;
                this.dialogVisibleRapor = true;

                // restoran dışı kart
                this.karsilastirmaVeriRestoranDisiKart.forEach(function (e) {
                    var obj = self.restoranDisiKart.find(data => data.urunId === e.urunId);
                    if (obj === undefined) {
                        self.restoranDisiKart.push(e)
                    }else{
                        obj.toplamAdet = parseInt(obj.toplamAdet) + parseInt(e.toplamAdet);
                        obj.toplamFiyat = parseFloat(obj.toplamFiyat) + parseFloat(e.toplamFiyat);
                    }
                });
                this.restoranDisiKart.forEach(function (a) {
                    self.toplamFiyatBilgisiRestoranDisiKart += parseFloat(a.toplamFiyat)
                });
                this.toplamVergiBilgisiRestoranDisiKart = this.toplamFiyatBilgisiRestoranDisiKart * (2.5 / 100)

                // restoran dışı nakit
                this.karsilastirmaVeriRestoranDisiNakit.forEach(function (e) {
                    var obj = self.restoranDisiNakit.find(data => data.urunId === e.urunId);
                    if (obj === undefined) {
                        self.restoranDisiNakit.push(e)
                    }else{
                        obj.toplamAdet = parseInt(obj.toplamAdet) + parseInt(e.toplamAdet);
                        obj.toplamFiyat = parseFloat(obj.toplamFiyat) + parseFloat(e.toplamFiyat);
                    }
                });
                this.restoranDisiNakit.forEach(function (a) {
                    self.toplamFiyatBilgisiRestoranDisiNakit += parseFloat(a.toplamFiyat)
                });
                this.toplamVergiBilgisiRestoranDisiNakit = this.toplamFiyatBilgisiRestoranDisiNakit * (2.5 / 100)

                // restoran içi kart
                this.karsilastirmaVeriRestoranIciKart.forEach(function (e) {
                    var obj = self.restoranIciKart.find(data => data.urunId === e.urunId);
                    if (obj === undefined) {
                        self.restoranIciKart.push(e)
                    }else{
                        obj.toplamAdet = parseInt(obj.toplamAdet) + parseInt(e.toplamAdet);
                        obj.toplamFiyat = parseFloat(obj.toplamFiyat) + parseFloat(e.toplamFiyat);
                    }
                });
                this.restoranIciKart.forEach(function (a) {
                    self.toplamFiyatBilgisiRestoranIciKart += parseFloat(a.toplamFiyat)
                });
                this.toplamVergiBilgisiRestoranIciKart = this.toplamFiyatBilgisiRestoranIciKart * (7.7 / 100)

                // restoran içi nakit
                this.karsilastirmaVeriRestoranIciNakit.forEach(function (e) {
                    var obj = self.restoranIciNakit.find(data => data.urunId === e.urunId);
                    if (obj === undefined) {
                        self.restoranIciNakit.push(e)
                    }else{
                        obj.toplamAdet = parseInt(obj.toplamAdet) + parseInt(e.toplamAdet);
                        obj.toplamFiyat = parseFloat(obj.toplamFiyat) + parseFloat(e.toplamFiyat);
                    }
                });
                this.restoranIciNakit.forEach(function (a) {
                    self.toplamFiyatBilgisiRestoranIciNakit += parseFloat(a.toplamFiyat)
                });
                this.toplamVergiBilgisiRestoranIciNakit = this.toplamFiyatBilgisiRestoranIciNakit * (7.7 / 100)


                this.toplamFiyatIc = this.toplamFiyatBilgisiRestoranIciNakit + this.toplamFiyatBilgisiRestoranIciKart;
                this.toplamFiyatDis = this.toplamFiyatBilgisiRestoranDisiNakit + this.toplamFiyatBilgisiRestoranDisiKart;
                this.toplamFiyatSon = this.toplamFiyatIc + this.toplamFiyatDis;


                this.toplamVergiDis = this.toplamFiyatDis * (2.5 / 100);
                this.toplamVergiIc = this.toplamFiyatIc * (7.7 / 100);
                this.toplamVergiSon = this.toplamVergiDis + this.toplamVergiIc

            },
            getRapor() {

                if (this.raporTuru !== '') {
                    this.karsilastirmaVeriRestoranDisiKart = [];
                    this.karsilastirmaVeriRestoranDisiNakit = [];
                    this.karsilastirmaVeriRestoranIciKart = [];
                    this.karsilastirmaVeriRestoranIciNakit = [];

                    this.restoranDisiKart = [];
                    this.restoranDisiNakit = [];
                    this.restoranIciKart = [];
                    this.restoranIciNakit = [];

                    this.toplamFiyatBilgisiRestoranDisiKart = 0;
                    this.toplamFiyatBilgisiRestoranDisiNakit = 0;
                    this.toplamFiyatBilgisiRestoranIciKart = 0;
                    this.toplamFiyatBilgisiRestoranIciNakit = 0;

                    this.toplamVergiBilgisiRestoranDisiKart = 0;
                    this.toplamVergiBilgisiRestoranDisiNakit = 0;
                    this.toplamVergiBilgisiRestoranIciKart = 0;
                    this.toplamVergiBilgisiRestoranIciNakit = 0;

                    this.toplamVergiSon = 0;
                    this.toplamVergiIc = 0;
                    this.toplamVergiDis = 0;
                    this.toplamFiyatIc = 0;
                    this.toplamFiyatDis = 0;
                    this.toplamFiyatSon = 0;


                    switch (this.raporTuru) {
                        case "0":
                            musteriService.dunRapor().then((response) => {
                                if (response.status === 200) {
                                    this.raporSiraNo = response.sira;
                                    this.raporDetayGor(response)
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                            break;
                        case "1":
                            musteriService.gunlukRapor().then((response) => {
                                if (response.status === 200) {
                                    this.raporSiraNo = response.sira;
                                    this.raporDetayGor(response)
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                            break;
                        case "2":
                            musteriService.aylikRapor().then((response) => {
                                if (response.status === 200) {
                                    this.raporSiraNo = response.sira;
                                    this.raporDetayGor(response)
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                            break;
                        case "3":
                            musteriService.yillikRapor().then((response) => {
                                if (response.status === 200) {
                                    this.raporSiraNo = response.sira;
                                    this.raporDetayGor(response)
                                } else {
                                    notification.Status(response.status, this)
                                }
                            });
                            break;
                    }
                } else {
                    notification.Status('warning', this, this.$t('bildirim.raporTuruSec'));
                }

            },
            getRaporName() {
                switch (this.raporTuru) {
                    case "0":
                        var raporAdi = this.$t('rapor.gunluk')
                        break;
                    case "1":
                        raporAdi = this.$t('rapor.gunluk')
                        break;
                    case "2":
                        raporAdi = this.$t('rapor.aylik')
                        break;
                    case "3":
                        raporAdi = this.$t('rapor.yillik')
                        break;
                    default:
                        raporAdi = '';
                }
                return raporAdi;
            }
        },
    }

    function raporCikti(raporTuru) {
        switch (raporTuru) {
            case "0":
                var tarihBilgisi = moment().subtract(1, 'days').format('yyyy/MM/DD');
                break;
            case "1":
                var tarihBilgisi = moment().format('yyyy/MM/DD');
                break;
            case "2":
                var tarihBilgisi = moment().locale('de').format('MMMM');
                break;
            case "3":
                var tarihBilgisi = moment().format('YYYY');
                break;
        }
        const ciktiCSS = "body {margin: 0 ; padding:0 0.5cm;width:7.2cm;font-family: monospace;font-size:13px}" +
            ".el-col-lg-15 {width: 62.5%; display: inline-block}" +
            ".el-col-lg-21 {width: 87.5%; display: inline-block}" +
            ".el-col-lg-3 {width: 12.5%; display: inline-block}" +
            ".raporUrunAdet {text-align: right}" +
            ".raporBasliklar {font-weight: bold}" +
            ".altCizgiYazdir {border-bottom: 1px solid black;padding-bottom: 5px; margin-bottom:5px;}" +
            ".ustCizgiCikti {border-top: 1px solid black; padding-top:5px;margin-top:5px}";
        const logo = '<img src="static/logo3.png" alt="logo" style="max-width: 100%; height: 50px"/>';

        var openWindow = window.open("", "", "_blank,width=400,height=500");
        openWindow.document.write('<div style="display: block !important;margin-top: 10px !important;filter: brightness(0); text-align: center">' + logo + '</div>');
        openWindow.document.write('<div style="margin: 20px !important; text-align: center; display: block !important;">Aarauerstrasse 21 5116 Schinznach-Bad, <br style="display: block !important;"><br style="display: block !important;"> Tel 056 525 91 91</div>');
        openWindow.document.write('<div style="display: block;margin-bottom: 15px;text-align: right">' + tarihBilgisi + '</div>');
        openWindow.document.write('<div class="FisBosluk">' + $(".yazdirIcerik").html() + '</div>');
        openWindow.document.write('<div style="display: block !important; text-align: center; margin-top: 20px !important;">MwSt. Nr. CHE-335.250.220</div>');
        openWindow.document.write('<div style="display: block !important; text-align: center; margin-top: 10px !important;">Danke für ihre Bestellung</div>');
        openWindow.document.write('<style>' + ciktiCSS + '</style>');
        openWindow.focus();
        setTimeout(function () {
            openWindow.print();
        }, 1000)
        setTimeout(function () {
            openWindow.close();
        }, 1500)
    }
</script>

<style lang="scss" scoped>
    @import '../assets/scss/_variables';

    .giris {
        background-image: url("../assets/images/katman/giris-bg.jpg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;

        .girisCard {
            margin: 5% 0;
            position: relative;

            .secimYap {
                text-align: center;

                .el-radio {
                    border-color: transparent;
                    padding-top: 0;
                    background: #ffffff;
                    height: 80px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    &.is-checked {
                        background: #f9f9f9;
                    }
                }

                .raporButon {
                    text-transform: uppercase;
                    text-align: center;
                    font-size: 18px !important;
                    font-weight: bold;
                    background: #ffffff;
                    color: #333333;
                    border: 3px solid #4a596a;
                    outline: none;
                    padding: 20px 40px;
                    z-index: 99;
                    position: relative;
                    cursor: pointer;
                    border-radius: 10px;
                }
            }
        }
    }


    @media (max-width: 1400px) {
        .giris {

            .list {
                .item {
                    width: 33.33%;
                }
            }
        }
    }


    @media (max-width: 1100px) {
        .giris {

            .list {
                .item {
                    width: 50%;
                }
            }
        }
    }


    @media (max-width: 900px) {
        .giris {


        }
    }

    @media (max-width: 768px) {
        .giris {


        }
    }

    @media (max-width: 480px) {
        .giris {


        }
    }

    .raporBasliklar {
        font-size: 18px !important;
        font-weight: bold
    }

    .sqlKonum {
        position: fixed;
        bottom: 15px;
        left: 15px;
        z-index: 99;

        button {
            background: #f9f9f9;
            padding: 20px 40px;
            outline: none;
            border: 3px solid #4a596a;
            border-radius: 10px;
            font-size: 16px !important;
            text-transform: uppercase;
            text-align: center;
            font-weight: bold;
            cursor: pointer;
        }
    }
</style>
