import WsProvider from './provider/WSProvider'

var base64 = require('base64_js');
var userDataKaderFoodKasa = localStorage.getItem("userDataKaderFoodKasa") ? JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa")))) : "";

var serviceName = "index";
var musteriService = {
    imageUrlBuyuk: WsProvider.resim + "/" + "HBT0082" + "/buyuk/",
    imageUrlOrta: WsProvider.resim + "/" + "HBT0082" + "/orta/",
    imageUrlKucuk: WsProvider.resim + "/" + "HBT0082" + "/kucuk/",
    imageUrlEnKucuk: WsProvider.resim + "/" + "HBT0082" + "/enkucuk/",
    path: "https://www.kaderfood.org.tr/",

    musteriListesi(durum, baslangic, limit) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                durum: durum,
                baslangic: baslangic,
                limit: limit,
                serviceName: "musteri",
                methodName: "musteriListesi"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    musteriDetay(musteriKodu) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                musteriKodu: musteriKodu,
                serviceName: "musteri",
                methodName: "musteriDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    musteriDurumGuncelle(musteriKodu, durum) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                musteriKodu: musteriKodu,
                durum: durum,
                serviceName: "musteri",
                methodName: "musteriDurumGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    siparisListesi(baslangic, limit, durum) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                baslangic: baslangic,
                limit: limit,
                durum: durum,
                serviceName: "musteri",
                methodName: "siparisListesi"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    musteriSiparisListesi(baslangic, limit, musteriKodu) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                baslangic: baslangic,
                limit: limit,
                musteriKodu: musteriKodu,
                serviceName: "musteri",
                methodName: "musteriSiparisListesi"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    siparisTanimlama(tur, adSoyad, telefon,adres,odeme,odemeSekli,masaId ,urunList) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                musteriKodu: '',
                tur: tur,
                adSoyad: adSoyad,
                telefon: telefon,
                adres: adres,
                il: '',
                ilce: '',
                odeme: odeme,
                odemeSekli: odemeSekli,
                siparisYeri: "Kasa",
                masaId : masaId ,
                urunList: urunList,
                serviceName: "musteri",
                methodName: "siparisTanimlama"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    siparisOdemeGuncelle(msiparisId,odeme, odemeSekli) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: "HBT0082",
                msiparisId: msiparisId,
                odeme: odeme,
                odemeSekli: odemeSekli,
                serviceName: "musteri",
                methodName: "siparisOdemeGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    siparisMasaDetay(masaId) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                masaId: masaId,
                serviceName: "musteri",
                methodName: "siparisMasaDetay"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    dunRapor () {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                serviceName: "musteri",
                methodName: "dunRapor"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    gunlukRapor () {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                serviceName: "musteri",
                methodName: "gunlukRapor"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    aylikRapor() {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                serviceName: "musteri",
                methodName: "aylikRapor"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    yillikRapor() {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                serviceName: "musteri",
                methodName: "yillikRapor"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
    masaAktarma(masaId, msiparisId) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                masaId: masaId,
                msiparisId: msiparisId,
                serviceName: "musteri",
                methodName: "masaAktarma"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    siparisCountDashboardKasa(tur,odemeSekli) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                tur: tur,
                odemeSekli: odemeSekli,
                serviceName: "musteri",
                methodName: "siparisCountDashboardKasa"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },

    siparisToplamDashboardKasa(tur,odemeSekli) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                tur: tur,
                odemeSekli: odemeSekli,
                serviceName: "musteri",
                methodName: "siparisToplamDashboardKasa"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },


    siparisGuncelle(msiparisId,urunList) {
        var userDataKaderFoodKasa = JSON.parse(decodeURI(base64.decode(localStorage.getItem("userDataKaderFoodKasa"))));
        var param = {
            data: {
                yonetimId: userDataKaderFoodKasa["yonetimId"],
                userToken: userDataKaderFoodKasa["token"],
                firmaKodu: userDataKaderFoodKasa["firmaKodu"],
                msiparisId: msiparisId,
                urunList: urunList,
                serviceName: "musteri",
                methodName: "siparisGuncelle"

            }
        };
        var result = new Promise(function (resolve, reject) {
            WsProvider.ajaxPost(serviceName, param).then((response) => {
                resolve(response)
            })
        });
        return result;

    },
}

export default musteriService

