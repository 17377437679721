<template>
	<div class="footer flex align-center justify-space-between above">
		<div class="footer-ust-img">
			<div class="hakanbt-logo">
				<div class="hakanbt">
					<a id="hakanbiltek" href="https://www.hakanbt.com.tr" title="Hakan Bilgi Teknolojileri">
						<img src="@/assets/images/hakanbt.png" alt="Hakan Bilgi Teknolojileri">
					</a>
				</div>
			</div>
		</div>
		<!--<div style="cursor: pointer">-->
			<!--<span style="color: #000" v-on:click="dialogVisibleYonetici=true">Hakan Bilgi Teknolojileri</span>-->
		<!--</div>-->
		<!--<version :dialogvisible.sync="dialogVisibleYonetici" :userdata="userdata"></version>-->
	</div>
</template>

<script>
	// import Version from '@/components/Version'

	export default {
		// components: {
		// 	Version,
		// },
		data() {
			return {
				userdata: {},
				dialogVisibleYonetici: false,
			}
		},

		name: 'Footer',
		props: ['position']
	}
</script>

<style lang="scss" scoped>
	@import '../assets/scss/_variables';
	@import '../assets/scss/_mixins';

	.footer {
		width: 100%;
		height: 40px;
		padding: 0 20px;
		box-sizing: border-box;
		background: #e3eaf0;
		margin-top: 2px;
		font-size: 14px;
		border-top-left-radius: 20px;

		&.above {
			box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
			z-index: 1;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: 0;
		}

		a {
			font-weight: bold;
			text-decoration: none;
			color: $text-color-accent;
		}

		.buy-btn {
			margin-left: 10px;
		}



		.footer-ust-img {
			position: relative;
			height: 90px;
			top: -34px;
		}

		.hakanbt-logo:before {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			top: -37px;
			left: -16px;
			border-style: solid;
			border-width: 0 30px 35px 30px;
			border-color: transparent transparent #e3eaf0 transparent;
		}

		.hakanbt-logo:after {
			content: "";
			position: absolute;
			width: 0;
			height: 0;
			top: -24px;
			left: -3px;
			border-style: solid;
			border-width:0px 17px 21px 17px;
			border-color: transparent transparent #0075c3 transparent;
		}

		.hakanbt-logo {
			position: absolute;
			bottom: -6px;
			left: 40px;
			height: 35%;
			width: 100%;
		}

		.hakanbt{width:28px !important;height:25px !important;overflow:hidden !important;position:relative !important;margin:0 !important;transition:all 0.5s ease-in-out 0.1s !important;}
		.hakanbt img{max-width: none !important;}
		.hakanbt:hover{width:125px !important;}
		.hakanbt:hover .social a:before{margin-left:-100px !important;}
		.hakanbt:hover .social a:after{margin-left:-5px !important;}



	}

	@media (max-width: 768px) {
		.footer {
			padding: 0 12px;
			border-radius: 0;
		}
	}
</style>
